import React from "react";
import Header from "./header.jsx";
import Footer1 from "./footer1.jsx";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer1 />
    </>
  );
};

export default Layout;
