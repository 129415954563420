import React from "react";
import { useNavigate } from "react-router-dom";
import "./ServerErrorPage.css";

const ServerErrorPage = () => {
  const navigate = useNavigate();
  const gotToNewPage = () => {
    navigate("/");
  };

  return (
    <>
      <section className="serverError">
        <div className="page_width">
          <div className="serverErrorContent">
            <h2> 404 </h2>
            <h4> Page Not Found </h4>
            <p>
              {" "}
              Oops, the page you are looking for does not exist, it might have
              been moved or deleted
            </p>
            <div className="home_btn">
              <button onClick={gotToNewPage}>Back to Home</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServerErrorPage;
