import React from "react";
import "./blog.css";
import Layout from "../../Layout/index.jsx";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection";
import blogBannerImg from "../../assets/images/blogBanner.webp";
import { blogData } from "./blog.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
        <link rel="canonical" href="https://zawiyafilms.ae/blog" />
        <meta
          name="description"
          content="Blogs related to Al Zawiya Films Production Upcoming Events and latest photography and videography tips and tricks for Dubai and USA. Contact Us at +971524480123"
        />
      </Helmet>
      <Layout>
        <h1 className="displayNo">Video Production Company in Dubai</h1>
        <PageBannerSection
          bannerImage={blogBannerImg}
          pageTitle="Blogs"
        ></PageBannerSection>
        <section className="blogSection">
          <div className="page_width">
            <div className="blogSection_content">
              {blogData.map((item) => (
                <div className="single_blog_data">
                  <Link to={item.link}>
                      <img
                        title={item.title}
                        src={item.fImage}
                        alt={item.alt}
                        loading="lazy"
                        width="100%"
                        height="auto"
                      />
                  </Link>
                  <h2>{item.title}</h2>
                  <p>{item.excert}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Blog;
