import React from "react";
import Layout from "../../Layout/index.jsx";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection.jsx";
import getQuoteImg from "../../assets/images/get_a_quote.webp";
import "./getaQuote.css";
import Testimonial from "../../global/testimonial/testimonial.jsx";
import ClientSection from "../../global/clients_section/client_section.jsx";
import GetaQuote from "./getaQuote.jsx";
import { Helmet } from "react-helmet-async";

const GetaQuotePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Get A Quote</title>
        <link rel="canonical" href="https://zawiyafilms.ae/get-a-quote" />
        <meta
          name="description"
          content="Al Zawiya Films Production best video production company provides photography and videography services in UAE and USA. Contact Us and get quotation +971524480123 "
        />
      </Helmet>
      <Layout>
        <h1 className="displayNo">Videography Services in UAE</h1>
        <PageBannerSection bannerImage={getQuoteImg} pageTitle="Get A Quote" />
        <GetaQuote></GetaQuote>
        <Testimonial></Testimonial>
        <ClientSection></ClientSection>
        <h3 className="displayNo">Videography Services in UAE</h3>
      </Layout>
    </>
  );
};

export default GetaQuotePage;
