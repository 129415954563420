import Home from "./pages/Home/home.jsx";
import ContactUs from "./pages/contactUs/contactUs.jsx";
import WhoWeAre from "./pages/whoWeAre/whoWeAre.jsx";
import WhatWeDo from "./pages/whatWeDo/whatWeDo.jsx";
import WhyChooseUs from "./pages/whyChooseUs/whyChooseUs.jsx";
import { Routes, Route } from "react-router-dom";
import Corporate from "./pages/services/corporate/corporate.jsx";
import GetaQuotePage from "./pages/getaQuote/getaQuotePage.jsx";
import Blog from "./pages/Blog/blog.jsx";
import RealEstate from "./pages/services/realestate/realEstate.jsx";
import ServerErrorPage from "./pages/404Page/ServerErrorPage.jsx";
import ProductPhotoTipsTricks from "./pages/PostPages/productPhotoTipsTricks.jsx";
import Headshot from "./pages/services/headshot/headshot.jsx";
import Product from "./pages/services/product/product.jsx";
import Food from "./pages/services/food/food.jsx";
import Wedding from "./pages/services/wedding/wedding.jsx";
import Automotive from "./pages/services/automotive/automotive.jsx";
import Birthday from "./pages/services/birthday/birthday.jsx";
import Fashion from "./pages/services/fashion/fashion.jsx";
import Reels from "./pages/services/reels/reels.jsx";
import Degree from "./pages/services/360Degree/360Degree.jsx";
import Timelapse from "./pages/services/timelapse/timelapse.jsx";
import BeechPhotography from "./pages/PostPages/beechPhotography.jsx";
import BeachWedding from "./pages/PostPages/beachWedding.jsx";
import TipsRealEstateListing from "./pages/PostPages/tipsRealEstateListing.jsx";
import EventPhotography from "./pages/PostPages/eventPhotography.jsx";
import TimelapseVideography from "./pages/PostPages/timelapseVideography.jsx";
import VideographerDubai from "./pages/PostPages/videographerDubai.jsx";
import PhotographyDubai from "./pages/PostPages/photographyDubai.jsx";
import CorporateEventVideography from "./pages/PostPages/corporateEventVideography.jsx";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/who-we-are" exact Component={WhoWeAre} />
        <Route path="/what-we-do" exact Component={WhatWeDo} />
        <Route path="/contact-us" exact Component={ContactUs} />
        <Route path="/why-us" exact Component={WhyChooseUs} />
        <Route path="/reels" exact Component={Reels} />
        <Route path="/360-video-booth" exact Component={Degree} />
        <Route path="/timelapse" exact Component={Timelapse} />
        <Route path="/corporate" exact Component={Corporate} />
        <Route path="/real-estate" exact Component={RealEstate} />
        <Route path="/headshot" exact Component={Headshot} />
        <Route path="/product" exact Component={Product} />
        <Route path="/food" exact Component={Food} />
        <Route path="/fashion" exact Component={Fashion} />
        <Route path="/automotive" exact Component={Automotive} />
        <Route path="/wedding" exact Component={Wedding} />
        <Route path="/birthday" exact Component={Birthday} />
        <Route path="/get-a-quote" exact Component={GetaQuotePage} />
        <Route path="/blog" exact Component={Blog} />
        <Route
          path="/product-photography-tips-tricks"
          exact
          Component={ProductPhotoTipsTricks}
        />
        <Route
          path="/beach-photography-in-dubai"
          exact
          Component={BeechPhotography}
        />
        <Route
          path="/beach-wedding-photography-in-dubai"
          exact
          Component={BeachWedding}
        />
        <Route
          path="/real-estate-photography-in-dubai"
          exact
          Component={TipsRealEstateListing}
        />
        <Route
          path="/event-photography-in-dubai"
          exact
          Component={EventPhotography}
        />
        <Route
          path="/timelapse-videography-in-dubai"
          exact
          Component={TimelapseVideography}
        />
        <Route
          path="/photographer-in-dubai"
          exact
          Component={PhotographyDubai}
        />
        <Route
          path="/videographer-in-dubai"
          exact
          Component={VideographerDubai}
        />
        <Route
          path="/corporate_videography_in_dubai"
          exact
          Component={CorporateEventVideography}
        />
        <Route path="/*" exact Component={ServerErrorPage} />
      </Routes>
    </>
  );
}

export default App;
