import React from "react";
import "./client_section.css";


const Clients = (props) => {
  return (
    <>
      <div className="clients_img">
        <img className="product--image cli_image" src={props.img} title={props.title} alt={props.alt} loading="lazy" width="100%" height="auto"/>
      </div>
    </>
  )
}

export default Clients;