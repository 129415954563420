import React from "react";
import "./style.css";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "./logo.png";
import ReactWhatsapp from "react-whatsapp";
import { FaHome } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";

const Footer1 = () => {
  return (
    <>
      <footer className="footer_bRed">
        <div className="page_width">
          <div className="footer1_content">
            <div className="footer_content_text">
              <p>Get Connected With Us On Social Network</p>
            </div>
            <div className="social_icons">
              <div className="facebook_icon">
                <a
                  href="https://www.facebook.com/zawiyafilms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
              </div>
              <div className="insta_icon">
                <a
                  href="https://www.instagram.com/zawiyafilms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
              <div className="vimeo_icon">
                <FaTiktok />
              </div>
              <div className="linkedin_icon">
                <a
                  href="https://www.linkedin.com/company/zawiyafilms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </div>
              <div className="youtube_icon">
                <a
                  href="https://www.youtube.com/@zawiyafilms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer_sec2">
        <div className="page_width">
          <div className="footer2_content">
            <div className="col1">
              <div className="footer_logo">
                <img src={logo} title="Best Video Production Company in Dubai" alt="Al Zawiya Films & Production" width="100%" height="auto" />
              </div>
              <div className="footer_text">
                <p>
                  Creating Lasting Memories with Professional Photography &
                  Videography. We Brings Your Vision to Life Through Lens and
                  Creativity. Trusted Photography & Videography Services in UAE
                  & USA.
                </p>
              </div>
            </div>
            <div className="col2">
              <div className="footer_title">
                <h4>Services</h4>
              </div>
              <ul className="footer_services">
                <li>
                  <Link to="/corporate">Corporate Shoots</Link>
                </li>
                <li>
                  <Link to="/real-estate">Real Estate Shoots </Link>
                </li>
                <li>
                  <Link to="/automotive">Automotive Shoots</Link>
                </li>
                <li>
                  <Link to="/wedding">Wedding Shoots</Link>
                </li>
                <li>
                  <Link to="/birthday">Birthday Shoots </Link>
                </li>
                <li>
                  <Link to="/product">Product Shoots </Link>
                </li>
              </ul>
            </div>
            <div className="col3">
              <div className="footer_title">
                <h4>Useful Links</h4>
              </div>
              <ul className="footer_services">
                <li>
                  <Link to="/who-we-are">About Us</Link>
                </li>
                <li>
                  <Link to="/what-we-do">Our Services</Link>
                </li>
                <li>
                  <Link to="/why-us">Why Choose Us</Link>
                </li>
                <li>
                  <ReactWhatsapp number="+971 524480123" className="whatsapp1">
                    Whatsapp
                  </ReactWhatsapp>
                </li>
                <li>
                  <Link to="/get-a-quote">Get A Quote</Link>
                </li>
                <li>
                  <a
                    href="https://www.google.com/maps/place/Al+Zawiya+Film's+Production/@25.255863,55.3015792,15z/data=!4m14!1m7!3m6!1s0x3e5f433953808e5d:0x16685017191ea9de!2sAl+Zawiya+Film's+Production!8m2!3d25.255863!4d55.3015792!16s%2Fg%2F11rxhkfq00!3m5!1s0x3e5f433953808e5d:0x16685017191ea9de!8m2!3d25.255863!4d55.3015792!16s%2Fg%2F11rxhkfq00?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Leave Us A Review
                  </a>
                </li>
              </ul>
            </div>
            <div className="col3">
              <div className="footer_title">
                <h4>Contact</h4>
              </div>
              <div className="f_icon">
                <div className="footer_icon">
                  <FaHome />
                </div>
                <div className="footer_icon_text">
                  <p>
                    Office 206, Al Attar Grand Building, Bank Street, Burjuman,
                    Dubai - UAE.
                  </p>
                </div>
              </div>
              <div className="f_icon">
                <div className="footer_icon">
                  <IoMdMail />
                </div>
                <div className="footer_icon_text">
                  <p>
                    <a href="mailto:info@zawiyafilms.ae">info@zawiyafilms.ae</a>
                  </p>
                </div>
              </div>
              <div className="f_icon">
                <div className="footer_icon">
                  <FaPhoneAlt />
                </div>
                <div className="footer_icon_text">
                  <p>
                    <a href="to:+971524480123">+971 52 448 0123</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="footer_copy_sec">
        <div className="page_width">
          <div className="footer_copy_content">
            <div className="copyright_icon">
              <FaRegCopyright />
            </div>
            <div className="copyright_content">
              2010 Copyright: Al Zawiya Films Production 
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer1;
