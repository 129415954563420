// Import Corporate Images Start */
import c1 from "../../assets/images/corporate/1.webp";
import c4 from "../../assets/images/corporate/4.webp";
import c5 from "../../assets/images/corporate/5.webp";
import c6 from "../../assets/images/corporate/6.webp";
import c7 from "../../assets/images/corporate/7.webp";
import c13 from "../../assets/images/corporate/13.webp";
import c18 from "../../assets/images/corporate/18.webp";
import c19 from "../../assets/images/corporate/19.webp";
import c20 from "../../assets/images/corporate/20.webp";
import c30 from "../../assets/images/corporate/30.webp";
import c31 from "../../assets/images/corporate/31.webp";
import c33 from "../../assets/images/corporate/33.webp";
import c36 from "../../assets/images/corporate/36.webp";
import c38 from "../../assets/images/corporate/38.webp";
import c39 from "../../assets/images/corporate/39.webp";
import c40 from "../../assets/images/corporate/40.webp";
import c41 from "../../assets/images/corporate/41.webp";
import c42 from "../../assets/images/corporate/42.webp";
import c43 from "../../assets/images/corporate/43.webp";
import c44 from "../../assets/images/corporate/44.webp";
import c46 from "../../assets/images/corporate/46.webp";
import c47 from "../../assets/images/corporate/47.webp";
import c51 from "../../assets/images/corporate/51.webp";
import c52 from "../../assets/images/corporate/52.webp";
import c53 from "../../assets/images/corporate/53.webp";
import c54 from "../../assets/images/corporate/54.webp";
import c56 from "../../assets/images/corporate/56.webp";
import c57 from "../../assets/images/corporate/57.webp";
import c58 from "../../assets/images/corporate/58.webp";
import c59 from "../../assets/images/corporate/59.webp";
import c63 from "../../assets/images/corporate/63.webp";
import c65 from "../../assets/images/corporate/65.webp";
import c66 from "../../assets/images/corporate/66.webp";
import c68 from "../../assets/images/corporate/68.webp";
import c69 from "../../assets/images/corporate/69.webp";
import c70 from "../../assets/images/corporate/70.webp";
import c71 from "../../assets/images/corporate/71.webp";
import c73 from "../../assets/images/corporate/73.webp";
import c74 from "../../assets/images/corporate/74.webp";
import c77 from "../../assets/images/corporate/77.webp";
import c78 from "../../assets/images/corporate/78.webp";
import c79 from "../../assets/images/corporate/79.webp";
import c83 from "../../assets/images/corporate/83.webp";
import c86 from "../../assets/images/corporate/86.webp";
import c87 from "../../assets/images/corporate/87.webp";
import c88 from "../../assets/images/corporate/88.webp";
import c90 from "../../assets/images/corporate/90.webp";
import c92 from "../../assets/images/corporate/92.webp";
import c94 from "../../assets/images/corporate/94.webp";
import c98 from "../../assets/images/corporate/98.webp";
import c99 from "../../assets/images/corporate/99.webp";
import c104 from "../../assets/images/corporate/104.webp";
import c105 from "../../assets/images/corporate/105.webp";
import c107 from "../../assets/images/corporate/107.webp";
/* Import Corporate Images End */

/* Import Birthday Images Start */
import b1 from "../../assets/images/birthday/1.webp";
import b2 from "../../assets/images/birthday/2.webp";
import b3 from "../../assets/images/birthday/3.webp";
import b4 from "../../assets/images/birthday/4.webp";
import b5 from "../../assets/images/birthday/5.webp";
import b6 from "../../assets/images/birthday/6.webp";
import b7 from "../../assets/images/birthday/7.webp";
import b8 from "../../assets/images/birthday/8.webp";
import b9 from "../../assets/images/birthday/9.webp";
import b10 from "../../assets/images/birthday/10.webp";
import b11 from "../../assets/images/birthday/11.webp";
import b12 from "../../assets/images/birthday/12.webp";
import b13 from "../../assets/images/birthday/13.webp";
import b14 from "../../assets/images/birthday/14.webp";
import b15 from "../../assets/images/birthday/15.webp";
import b16 from "../../assets/images/birthday/16.webp";
import b17 from "../../assets/images/birthday/17.webp";
import b18 from "../../assets/images/birthday/18.webp";
import b19 from "../../assets/images/birthday/19.webp";
import b20 from "../../assets/images/birthday/20.webp";
import b21 from "../../assets/images/birthday/21.webp";
import b22 from "../../assets/images/birthday/22.webp";
import b23 from "../../assets/images/birthday/23.webp";
/* Import Birthday Images Ends */

/* Import Headshot Images Start */
import hs1 from "../../assets/images/headshot/1.webp";
import hs2 from "../../assets/images/headshot/2.webp";
import hs3 from "../../assets/images/headshot/3.webp";
import hs4 from "../../assets/images/headshot/4.webp";
import hs5 from "../../assets/images/headshot/5.webp";
import hs6 from "../../assets/images/headshot/6.webp";
import hs7 from "../../assets/images/headshot/7.webp";
import hs8 from "../../assets/images/headshot/8.webp";
import hs9 from "../../assets/images/headshot/9.webp";
import hs10 from "../../assets/images/headshot/10.webp";
import hs11 from "../../assets/images/headshot/11.webp";
import hs12 from "../../assets/images/headshot/12.webp";
import hs13 from "../../assets/images/headshot/13.webp";
import hs14 from "../../assets/images/headshot/14.webp";
import hs15 from "../../assets/images/headshot/15.webp";
import hs16 from "../../assets/images/headshot/16.webp";
import hs17 from "../../assets/images/headshot/17.webp";
import hs18 from "../../assets/images/headshot/18.webp";
import hs20 from "../../assets/images/headshot/20.webp";
import hs21 from "../../assets/images/headshot/21.webp";
import hs22 from "../../assets/images/headshot/22.webp";
/* Import Headshot Images End */

//Import Fashion Images Start
import f1 from "../../assets/images/fashion/1.webp";
import f2 from "../../assets/images/fashion/2.webp";
import f3 from "../../assets/images/fashion/3.webp";
import f4 from "../../assets/images/fashion/4.webp";
import f5 from "../../assets/images/fashion/5.webp";
import f6 from "../../assets/images/fashion/6.webp";
import f7 from "../../assets/images/fashion/7.webp";
import f8 from "../../assets/images/fashion/8.webp";
import f9 from "../../assets/images/fashion/9.webp";
import f10 from "../../assets/images/fashion/10.webp";
import f11 from "../../assets/images/fashion/11.webp";
import f12 from "../../assets/images/fashion/12.webp";
import f14 from "../../assets/images/fashion/14.webp";
import f15 from "../../assets/images/fashion/15.webp";
import f16 from "../../assets/images/fashion/16.webp";
import f18 from "../../assets/images/fashion/18.webp";
import f19 from "../../assets/images/fashion/19.webp";
import f20 from "../../assets/images/fashion/20.webp";
import f21 from "../../assets/images/fashion/21.webp";
import f22 from "../../assets/images/fashion/22.webp";
import f27 from "../../assets/images/fashion/27.webp";
import f29 from "../../assets/images/fashion/29.webp";
import f30 from "../../assets/images/fashion/30.webp";
import f31 from "../../assets/images/fashion/31.webp";
import f32 from "../../assets/images/fashion/32.webp";
import f33 from "../../assets/images/fashion/33.webp";
import f34 from "../../assets/images/fashion/34.webp";
import f35 from "../../assets/images/fashion/35.webp";
import f36 from "../../assets/images/fashion/36.webp";
import f37 from "../../assets/images/fashion/37.webp";
import f41 from "../../assets/images/fashion/41.webp";
import f43 from "../../assets/images/fashion/43.webp";
import f44 from "../../assets/images/fashion/44.webp";
import f45 from "../../assets/images/fashion/45.webp";
import f46 from "../../assets/images/fashion/46.webp";
import f47 from "../../assets/images/fashion/47.webp";
import f48 from "../../assets/images/fashion/48.webp";
import f49 from "../../assets/images/fashion/49.webp";
import f50 from "../../assets/images/fashion/50.webp";
import f51 from "../../assets/images/fashion/51.webp";
import f53 from "../../assets/images/fashion/53.webp";
import f55 from "../../assets/images/fashion/55.webp";
import f56 from "../../assets/images/fashion/56.webp";
import f57 from "../../assets/images/fashion/57.webp";
import f58 from "../../assets/images/fashion/58.webp";
import f59 from "../../assets/images/fashion/59.webp";
import f60 from "../../assets/images/fashion/60.webp";
import f62 from "../../assets/images/fashion/62.webp";
import f63 from "../../assets/images/fashion/63.webp";
import f64 from "../../assets/images/fashion/64.webp";
import f68 from "../../assets/images/fashion/68.webp";
import f69 from "../../assets/images/fashion/69.webp";
import f71 from "../../assets/images/fashion/71.webp";
import f74 from "../../assets/images/fashion/74.webp";
import f75 from "../../assets/images/fashion/75.webp";
import f76 from "../../assets/images/fashion/76.webp";
import f77 from "../../assets/images/fashion/77.webp";
//Import Fashion Images End

//Import Wedding Images Start
import w00 from "../../assets/images/wedding/00.webp";
import w01 from "../../assets/images/wedding/01.webp";
import w02 from "../../assets/images/wedding/02.webp";
import w03 from "../../assets/images/wedding/03.webp";
import w04 from "../../assets/images/wedding/04.webp";
import w05 from "../../assets/images/wedding/05.webp";
import w06 from "../../assets/images/wedding/06.webp";
import w07 from "../../assets/images/wedding/07.webp";
import w08 from "../../assets/images/wedding/08.webp";
import w09 from "../../assets/images/wedding/09.webp";
import w010 from "../../assets/images/wedding/010.webp";
import w011 from "../../assets/images/wedding/011.webp";
import w012 from "../../assets/images/wedding/012.webp";
import w013 from "../../assets/images/wedding/013.webp";
import w015 from "../../assets/images/wedding/015.webp";
import w1 from "../../assets/images/wedding/1.webp";
import w2 from "../../assets/images/wedding/2.webp";
import w3 from "../../assets/images/wedding/3.webp";
import w4 from "../../assets/images/wedding/4.webp";
import w5 from "../../assets/images/wedding/5.webp";
import w6 from "../../assets/images/wedding/6.webp";
import w7 from "../../assets/images/wedding/7.webp";
import w10 from "../../assets/images/wedding/10.webp";
import w11 from "../../assets/images/wedding/11.webp";
import w14 from "../../assets/images/wedding/14.webp";
import w17 from "../../assets/images/wedding/17.webp";
import w18 from "../../assets/images/wedding/18.webp";
import w19 from "../../assets/images/wedding/19.webp";
import w20 from "../../assets/images/wedding/20.webp";
import w21 from "../../assets/images/wedding/21.webp";
import w22 from "../../assets/images/wedding/22.webp";
import w23 from "../../assets/images/wedding/23.webp";
import w24 from "../../assets/images/wedding/24.webp";
import w25 from "../../assets/images/wedding/25.webp";
import w26 from "../../assets/images/wedding/26.webp";
import w27 from "../../assets/images/wedding/27.webp";
import w29 from "../../assets/images/wedding/29.webp";
import w30 from "../../assets/images/wedding/30.webp";
import w31 from "../../assets/images/wedding/31.webp";
import w33 from "../../assets/images/wedding/33.webp";
import w34 from "../../assets/images/wedding/34.webp";
import w36 from "../../assets/images/wedding/36.webp";
import w37 from "../../assets/images/wedding/37.webp";
import w41 from "../../assets/images/wedding/41.webp";
import w43 from "../../assets/images/wedding/43.webp";
import w45 from "../../assets/images/wedding/45.webp";
import w46 from "../../assets/images/wedding/46.webp";
import w47 from "../../assets/images/wedding/47.webp";
import w49 from "../../assets/images/wedding/49.webp";
import w50 from "../../assets/images/wedding/50.webp";
import w51 from "../../assets/images/wedding/51.webp";
//Import Wedding Images End

//Import Real Estate Images Start
import re1 from "../../assets/images/realestate/1.webp";
import re2 from "../../assets/images/realestate/2.webp";
import re4 from "../../assets/images/realestate/4.webp";
import re5 from "../../assets/images/realestate/5.webp";
import re6 from "../../assets/images/realestate/6.webp";
import re7 from "../../assets/images/realestate/7.webp";
import re9 from "../../assets/images/realestate/9.webp";
import re10 from "../../assets/images/realestate/10.webp";
import re11 from "../../assets/images/realestate/11.webp";
import re12 from "../../assets/images/realestate/12.webp";
import re13 from "../../assets/images/realestate/13.webp";
import re14 from "../../assets/images/realestate/14.webp";
import re15 from "../../assets/images/realestate/15.webp";
import re16 from "../../assets/images/realestate/16.webp";
import re18 from "../../assets/images/realestate/18.webp";
import re20 from "../../assets/images/realestate/20.webp";
import re21 from "../../assets/images/realestate/21.webp";
import re22 from "../../assets/images/realestate/22.webp";
import re23 from "../../assets/images/realestate/23.webp";
import re24 from "../../assets/images/realestate/24.webp";
import re25 from "../../assets/images/realestate/25.webp";
import re26 from "../../assets/images/realestate/26.webp";
import re27 from "../../assets/images/realestate/27.webp";
import re28 from "../../assets/images/realestate/28.webp";
//Import Real Estate Images End

// Import Product Images Start
import p14 from "../../assets/images/product/14.webp";
import p17 from "../../assets/images/product/17.webp";
import p18 from "../../assets/images/product/18.webp";
import p19 from "../../assets/images/product/19.webp";
import p20 from "../../assets/images/product/20.webp";
import p22 from "../../assets/images/product/22.webp";
import p23 from "../../assets/images/product/23.webp";
import p24 from "../../assets/images/product/24.webp";
import p26 from "../../assets/images/product/26.webp";
import p27 from "../../assets/images/product/27.webp";
import p28 from "../../assets/images/product/28.webp";
import p33 from "../../assets/images/product/33.webp";
import p35 from "../../assets/images/product/35.webp";
import p39 from "../../assets/images/product/39.webp";
import p40 from "../../assets/images/product/40.webp";
import p45 from "../../assets/images/product/45.webp";
import p47 from "../../assets/images/product/47.webp";
import p49 from "../../assets/images/product/49.webp";
import p51 from "../../assets/images/product/51.webp";
import p53 from "../../assets/images/product/53.webp";
import p55 from "../../assets/images/product/55.webp";
import p58 from "../../assets/images/product/58.webp";
import p60 from "../../assets/images/product/60.webp";
import p61 from "../../assets/images/product/61.webp";
import p62 from "../../assets/images/product/62.webp";
import p63 from "../../assets/images/product/63.webp";
import p64 from "../../assets/images/product/64.webp";
import p65 from "../../assets/images/product/65.webp";
import p68 from "../../assets/images/product/68.webp";
import p80 from "../../assets/images/product/80.webp";
import p81 from "../../assets/images/product/81.webp";
import p84 from "../../assets/images/product/84.webp";
import p85 from "../../assets/images/product/85.webp";
import p86 from "../../assets/images/product/86.webp";
import p87 from "../../assets/images/product/87.webp";
// Import Product Images End

// Import Food Images Start
import food1 from "../../assets/images/food/1.webp";
import food2 from "../../assets/images/food/2.webp";
import food3 from "../../assets/images/food/3.webp";
import food4 from "../../assets/images/food/4.webp";
import food5 from "../../assets/images/food/5.webp";
import food6 from "../../assets/images/food/6.webp";
import food7 from "../../assets/images/food/7.webp";
import food8 from "../../assets/images/food/8.webp";
import food10 from "../../assets/images/food/10.webp";
import food11 from "../../assets/images/food/11.webp";
import food12 from "../../assets/images/food/12.webp";
import food13 from "../../assets/images/food/13.webp";
import food14 from "../../assets/images/food/14.webp";
import food15 from "../../assets/images/food/15.webp";
import food16 from "../../assets/images/food/16.webp";
import food17 from "../../assets/images/food/17.webp";
import food18 from "../../assets/images/food/18.webp";
import food19 from "../../assets/images/food/19.webp";
import food20 from "../../assets/images/food/20.webp";
import food21 from "../../assets/images/food/21.webp";
import food22 from "../../assets/images/food/22.webp";
import food23 from "../../assets/images/food/23.webp";
import food24 from "../../assets/images/food/24.webp";
import food26 from "../../assets/images/food/26.webp";
import food27 from "../../assets/images/food/27.webp";
import food28 from "../../assets/images/food/28.webp";
import food29 from "../../assets/images/food/29.webp";
//Import Food Images End

//Import Automotive Images Start

import a3 from "../../assets/images/automotive/3.webp";
import a5 from "../../assets/images/automotive/5.webp";
import a6 from "../../assets/images/automotive/6.webp";
import a7 from "../../assets/images/automotive/7.webp";
import a9 from "../../assets/images/automotive/9.webp";
import a10 from "../../assets/images/automotive/10.webp";
import a13 from "../../assets/images/automotive/13.webp";
import a16 from "../../assets/images/automotive/16.webp";
import a17 from "../../assets/images/automotive/17.webp";
import a18 from "../../assets/images/automotive/18.webp";
import a19 from "../../assets/images/automotive/19.webp";
import a20 from "../../assets/images/automotive/20.webp";
import a22 from "../../assets/images/automotive/22.webp";
import a23 from "../../assets/images/automotive/23.webp";
import a24 from "../../assets/images/automotive/24.webp";
import a25 from "../../assets/images/automotive/25.webp";
import a26 from "../../assets/images/automotive/26.webp";
import a27 from "../../assets/images/automotive/27.webp";
import a28 from "../../assets/images/automotive/28.webp";
import a29 from "../../assets/images/automotive/29.webp";
import a30 from "../../assets/images/automotive/30.webp";
import a31 from "../../assets/images/automotive/31.webp";
import a32 from "../../assets/images/automotive/32.webp";
import a33 from "../../assets/images/automotive/33.webp";
import a34 from "../../assets/images/automotive/34.webp";
import a35 from "../../assets/images/automotive/35.webp";
//Import Automotive Images End

//Import 360 Degree Images Start
import d1 from "../../assets/images/360Degree/1.webp";
import d2 from "../../assets/images/360Degree/2.webp";
import d3 from "../../assets/images/360Degree/3.webp";
import d4 from "../../assets/images/360Degree/4.webp";
import d5 from "../../assets/images/360Degree/5.webp";
import d6 from "../../assets/images/360Degree/6.webp";
//Import 360 Degree Images End

//Import Timelapse Images Start
import t1 from "../../assets/images/timelapse/1.webp";
import t2 from "../../assets/images/timelapse/2.webp";
import t3 from "../../assets/images/timelapse/3.webp";
import t4 from "../../assets/images/timelapse/4.webp";
import t5 from "../../assets/images/timelapse/5.webp";
import t8 from "../../assets/images/timelapse/8.webp";
import t9 from "../../assets/images/timelapse/9.webp";
import t10 from "../../assets/images/timelapse/10.webp";
import t11 from "../../assets/images/timelapse/11.webp";
import t12 from "../../assets/images/timelapse/12.webp";
import t15 from "../../assets/images/timelapse/15.webp";
import t17 from "../../assets/images/timelapse/17.webp";
import t18 from "../../assets/images/timelapse/18.webp";
import t19 from "../../assets/images/timelapse/19.webp";
import t20 from "../../assets/images/timelapse/20.webp";
import t21 from "../../assets/images/timelapse/21.webp";
import t22 from "../../assets/images/timelapse/22.webp";
import t23 from "../../assets/images/timelapse/23.webp";
import t25 from "../../assets/images/timelapse/25.webp";
import t27 from "../../assets/images/timelapse/27.webp";
import t29 from "../../assets/images/timelapse/29.webp";
import t30 from "../../assets/images/timelapse/30.webp";
import t31 from "../../assets/images/timelapse/31.webp";
import t32 from "../../assets/images/timelapse/32.webp";
import t33 from "../../assets/images/timelapse/33.webp";
import t35 from "../../assets/images/timelapse/35.webp";
import t37 from "../../assets/images/timelapse/37.webp";
import t39 from "../../assets/images/timelapse/39.webp";
import t40 from "../../assets/images/timelapse/40.webp";
//Import Timelapse Images End

export const corporateImages = [
  {
    id: 4,
    src: c4,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 5,
    src: c5,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 6,
    src: c6,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 1,
    src: c1,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 7,
    src: c7,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 13,
    src: c13,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 18,
    src: c18,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 19,
    src: c19,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 20,
    src: c20,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 30,
    src: c30,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 31,
    src: c31,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 33,
    src: c33,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 36,
    src: c36,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 38,
    src: c38,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 39,
    src: c39,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 40,
    src: c40,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 41,
    src: c41,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 42,
    src: c42,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 43,
    src: c43,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 44,
    src: c44,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 46,
    src: c46,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 47,
    src: c47,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 51,
    src: c51,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 52,
    src: c52,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 53,
    src: c53,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 54,
    src: c54,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 56,
    src: c56,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 57,
    src: c57,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 58,
    src: c58,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 59,
    src: c59,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 63,
    src: c63,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 65,
    src: c65,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 66,
    src: c66,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 68,
    src: c68,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 69,
    src: c69,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 70,
    src: c70,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 71,
    src: c71,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 73,
    src: c73,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 74,
    src: c74,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 77,
    src: c77,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 78,
    src: c78,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 79,
    src: c79,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 83,
    src: c83,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 107,
    src: c107,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 86,
    src: c86,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 87,
    src: c87,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 88,
    src: c88,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 90,
    src: c90,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 92,
    src: c92,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 94,
    src: c94,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 98,
    src: c98,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 99,
    src: c99,
    title: "corporate photography in dubai",
    description: "",
  },
  {
    id: 104,
    src: c104,
    title: "corporate videography in dubai",
    description: "",
  },
  {
    id: 105,
    src: c105,
    title: "corporate videography in dubai",
    description: "",
  },
];
export const birthdayImages = [
  {
    id: 1,
    src: b1,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 2,
    src: b2,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 3,
    src: b3,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 4,
    src: b4,
    title: "birthday videography  in dubai",
    description: "",
  },
  {
    id: 5,
    src: b5,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 6,
    src: b6,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 7,
    src: b7,
    title: "birthday videography  in dubai",
    description: "",
  },
  {
    id: 8,
    src: b8,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 9,
    src: b9,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 10,
    src: b10,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 11,
    src: b11,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 12,
    src: b12,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 14,
    src: b14,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 15,
    src: b15,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 16,
    src: b16,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 17,
    src: b17,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 18,
    src: b18,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 19,
    src: b19,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 20,
    src: b20,
    title: "birthday videography in dubai",
    description: "",
  },
  {
    id: 22,
    src: b22,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 23,
    src: b23,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 21,
    src: b21,
    title: "birthday photography in dubai",
    description: "",
  },
  {
    id: 13,
    src: b13,
    title: "birthday photography in dubai",
    description: "",
  },
];
export const headshotImages = [
  {
    id: 3,
    src: hs3,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 5,
    src: hs5,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 4,
    src: hs4,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 6,
    src: hs6,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 7,
    src: hs7,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 8,
    src: hs8,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 1,
    src: hs1,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 2,
    src: hs2,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 11,
    src: hs11,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 9,
    src: hs9,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 17,
    src: hs17,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 12,
    src: hs12,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 10,
    src: hs10,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 15,
    src: hs15,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 13,
    src: hs13,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 14,
    src: hs14,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 16,
    src: hs16,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 21,
    src: hs21,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 20,
    src: hs20,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 18,
    src: hs18,
    title: "headshot photography in dubai",
    description: "",
  },
  {
    id: 22,
    src: hs22,
    title: "headshot photography in dubai",
    description: "",
  },
];
export const fashionImages = [
  {
    id: 1,
    src: f1,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 2,
    src: f2,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 3,
    src: f3,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 5,
    src: f5,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 6,
    src: f6,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 7,
    src: f7,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 4,
    src: f4,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 18,
    src: f18,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 19,
    src: f19,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 20,
    src: f20,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 21,
    src: f21,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 22,
    src: f22,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 43,
    src: f43,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 44,
    src: f44,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 45,
    src: f45,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 46,
    src: f46,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 47,
    src: f47,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 48,
    src: f48,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 49,
    src: f49,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 50,
    src: f50,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 56,
    src: f56,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 51,
    src: f51,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 41,
    src: f41,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 57,
    src: f57,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 58,
    src: f58,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 59,
    src: f59,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 60,
    src: f60,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 8,
    src: f8,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 9,
    src: f9,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 10,
    src: f10,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 11,
    src: f11,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 12,
    src: f12,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 14,
    src: f14,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 15,
    src: f15,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 16,
    src: f16,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 27,
    src: f27,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 29,
    src: f29,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 30,
    src: f30,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 31,
    src: f31,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 32,
    src: f32,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 33,
    src: f33,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 34,
    src: f34,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 35,
    src: f35,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 36,
    src: f36,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 37,
    src: f37,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 53,
    src: f53,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 55,
    src: f55,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 62,
    src: f62,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 63,
    src: f63,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 64,
    src: f64,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 68,
    src: f68,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 69,
    src: f69,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 71,
    src: f71,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 74,
    src: f74,
    title: "fashion videography in dubai",
    description: "",
  },
  {
    id: 75,
    src: f75,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 76,
    src: f76,
    title: "fashion photography in dubai",
    description: "",
  },
  {
    id: 77,
    src: f77,
    title: "fashion videography in dubai",
    description: "",
  },
];
export const weddingImages = [
  {
    id: 0,
    src: w00,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 1,
    src: w01,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 2,
    src: w02,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 3,
    src: w03,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 4,
    src: w04,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 55,
    src: w05,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 56,
    src: w06,
    title: "wedding videography in dubai",
    description: "",
  },{
    id: 57,
    src: w07,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 58,
    src: w08,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 59,
    src: w09,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 60,
    src: w010,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 62,
    src: w012,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 63,
    src: w013,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 64,
    src: w015,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 65,
    src: w011,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 5,
    src: w5,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 6,
    src: w6,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 7,
    src: w7,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 10,
    src: w10,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 11,
    src: w11,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 14,
    src: w14,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 17,
    src: w17,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 18,
    src: w18,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 19,
    src: w19,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 20,
    src: w20,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 21,
    src: w21,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 22,
    src: w22,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 25,
    src: w25,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 26,
    src: w26,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 27,
    src: w27,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 23,
    src: w23,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 24,
    src: w24,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 1,
    src: w1,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 2,
    src: w2,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 3,
    src: w3,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 4,
    src: w4,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 29,
    src: w29,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 30,
    src: w30,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 31,
    src: w31,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 33,
    src: w33,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 34,
    src: w34,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 36,
    src: w36,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 37,
    src: w37,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 41,
    src: w41,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 46,
    src: w46,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 47,
    src: w47,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 49,
    src: w49,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 50,
    src: w50,
    title: "wedding photography in dubai",
    description: "",
  },
  {
    id: 51,
    src: w51,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 43,
    src: w43,
    title: "wedding videography in dubai",
    description: "",
  },
  {
    id: 45,
    src: w45,
    title: "wedding photography in dubai",
    description: "",
  },
];
export const realImages = [
  {
    id: 22,
    src: re22,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 23,
    src: re23,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 24,
    src: re24,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 25,
    src: re25,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 26,
    src: re26,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 27,
    src: re27,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 9,
    src: re9,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 10,
    src: re10,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 11,
    src: re11,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 28,
    src: re28,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 20,
    src: re20,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 21,
    src: re21,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 1,
    src: re1,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 2,
    src: re2,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 4,
    src: re4,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 5,
    src: re5,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 6,
    src: re6,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 7,
    src: re7,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 12,
    src: re12,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 13,
    src: re13,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 14,
    src: re14,
    title: "real estate Photography in Dubai",
    description: "",
  },
  {
    id: 15,
    src: re15,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 16,
    src: re16,
    title: "real estate Videography in Dubai",
    description: "",
  },
  {
    id: 18,
    src: re18,
    title: "real estate Photography in Dubai",
    description: "",
  },
];
export const productImages = [
  {
    id: 14,
    src: p14,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 18,
    src: p18,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 17,
    src: p17,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 20,
    src: p20,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 19,
    src: p19,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 39,
    src: p39,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 22,
    src: p22,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 28,
    src: p28,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 27,
    src: p27,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 23,
    src: p23,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 33,
    src: p33,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 40,
    src: p40,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 24,
    src: p24,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 26,
    src: p26,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 35,
    src: p35,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 45,
    src: p45,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 47,
    src: p47,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 49,
    src: p49,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 51,
    src: p51,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 53,
    src: p53,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 55,
    src: p55,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 60,
    src: p60,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 62,
    src: p62,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 63,
    src: p63,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 61,
    src: p61,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 64,
    src: p64,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 65,
    src: p65,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 81,
    src: p81,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 68,
    src: p68,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 84,
    src: p84,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 80,
    src: p80,
    title: "product Videography in Dubai",
    description: "",
  },
  {
    id: 85,
    src: p85,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 58,
    src: p58,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 86,
    src: p86,
    title: "product Photography in Dubai",
    description: "",
  },
  {
    id: 87,
    src: p87,
    title: "product Photography in Dubai",
    description: "",
  },
];
export const foodImages = [
  {
    id: 1,
    src: food1,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 2,
    src: food2,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 6,
    src: food6,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 10,
    src: food10,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 4,
    src: food4,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 8,
    src: food8,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 3,
    src: food3,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 5,
    src: food5,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 7,
    src: food7,
    title: "food Videography in Dubai",
    description: "",
  },

  {
    id: 11,
    src: food11,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 12,
    src: food12,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 16,
    src: food16,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 13,
    src: food13,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 14,
    src: food14,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 15,
    src: food15,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 17,
    src: food17,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 28,
    src: food28,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 20,
    src: food20,
    title: "food Vidography in Dubai",
    description: "",
  },
  {
    id: 18,
    src: food18,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 19,
    src: food19,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 21,
    src: food21,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 23,
    src: food23,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 24,
    src: food24,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 26,
    src: food26,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 27,
    src: food27,
    title: "food Videography in Dubai",
    description: "",
  },
  {
    id: 29,
    src: food29,
    title: "food Photography in Dubai",
    description: "",
  },
  {
    id: 22,
    src: food22,
    title: "food Photography in Dubai",
    description: "",
  },
];
export const automotiveImages = [
  {
    id: 5,
    src: a5,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 6,
    src: a6,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 22,
    src: a22,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 9,
    src: a9,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 10,
    src: a10,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 13,
    src: a13,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 34,
    src: a34,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 31,
    src: a31,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 17,
    src: a17,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 7,
    src: a7,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 16,
    src: a16,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 30,
    src: a30,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 18,
    src: a18,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 19,
    src: a19,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 20,
    src: a20,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 23,
    src: a23,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 24,
    src: a24,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 25,
    src: a25,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 26,
    src: a26,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 27,
    src: a27,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 28,
    src: a28,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 29,
    src: a29,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 32,
    src: a32,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 33,
    src: a33,
    title: "automotive Photography in Dubai",
    description: "",
  },
  {
    id: 3,
    src: a3,
    title: "automotive Videography in Dubai",
    description: "",
  },
  {
    id: 35,
    src: a35,
    title: "automotive Videography in Dubai",
    description: "",
  },
];
export const degreeImages = [
  {
    id: 1,
    src: d1,
    title: "360 Degree Photography in Dubai",
    description: "",
  },
  {
    id: 2,
    src: d2,
    title: "360 Degree Photography in Dubai",
    description: "",
  },
  {
    id: 3,
    src: d3,
    title: "360 Degree Videography in Dubai",
    description: "",
  },
  {
    id: 4,
    src: d4,
    title: "360 Degree Videography in Dubai",
    description: "",
  },
  {
    id: 5,
    src: d5,
    title: "360 Degree Photography in Dubai",
    description: "",
  },
  {
    id: 6,
    src: d6,
    title: "360 Degree Photography in Dubai",
    description: "",
  },
];
export const timelapseImages = [
  {
    id: 3,
    src: t3,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 4,
    src: t4,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 5,
    src: t5,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 8,
    src: t8,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 9,
    src: t9,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 11,
    src: t11,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 12,
    src: t12,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 15,
    src: t15,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 17,
    src: t17,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 18,
    src: t18,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 19,
    src: t19,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 20,
    src: t20,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 21,
    src: t21,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 22,
    src: t22,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 23,
    src: t23,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 25,
    src: t25,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 27,
    src: t27,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 29,
    src: t29,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 30,
    src: t30,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 31,
    src: t31,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 32,
    src: t32,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 33,
    src: t33,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 35,
    src: t35,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 37,
    src: t37,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 39,
    src: t39,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 40,
    src: t40,
    title: "Timelapse Videography in Dubai",
    description: "",
  },
  {
    id: 28,
    src: "",
    title: "",
    description: "",
  },
  {
    id: 1,
    src: t1,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 2,
    src: t2,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
  {
    id: 10,
    src: t10,
    title: "Timelapse Photography in Dubai",
    description: "",
  },
];
