import React, { useRef, useEffect, useState } from "react";
import Layout from "../../Layout/index.jsx";
import heroimage from "../../assets/images/hersection1.webp";
import "./homestyle.css";
import { FaPlay } from "react-icons/fa";
import "react-multi-carousel/lib/styles.css";
import Portfolio from "./portfolio";
import ClientSection from "../../global/clients_section/client_section.jsx";
import { Typewriter} from "react-simple-typewriter";
import {
  corporateData,
  birthdayData,
  headshotData,
  fashionData,
  weddingData,
  realEstateData,
  productData,
  foodData,
  automotiveData,
  degreeData,
  timelapseData,
  settings,
} from "./portfoliodata";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import about from "../../assets/images/homeAbout.webp";
import gisecimage from "../../assets/images/gisec.webp";
import aimImage from "../../assets/images/AIM-Congress.webp";
import aimVideo from "../../assets/videos/aim-congress.mp4";
import driftx from "../../assets/images/driftx.webp";
import driftxVideo from "../../assets/videos/driftx.mp4";
import ReactPlayer from "react-player";
import azizi from "../../assets/images/azizi riveria.webp";
import Testimonial from "../../global/testimonial/testimonial.jsx";
import WhyChooseUsSection from "../../global/whyChooseUsSection/whyChooseUsSection.jsx";
import ActiveClientsSection from "../../global/activeClientsSection/activeClientsSection.jsx";
import Faq from "../../global/faqSection/faq.jsx";
import { faqData } from "../../global/faqSection/faq.js";

import box from "../../assets/videos/reels/box.jpeg";
import airShow from "../../assets/videos/reels/airShow.jpeg";
import book from "../../assets/videos/reels/book.jpeg";
import cafe from "../../assets/videos/reels/cafe.jpeg";
import edge from "../../assets/videos/reels/edge.jpeg";
import game from "../../assets/videos/reels/game.jpeg";
import gulfood from "../../assets/videos/reels/gulfood.jpeg";
import shake from "../../assets/videos/reels/shake.jpeg";
import pizza2 from "../../assets/videos/reels/pizza2.jpeg";
import pizzaMaking from "../../assets/videos/foodVideos/pizzaMaking.jpeg";
import coffee from "../../assets/videos/reels/coffee.jpeg";

import Video1 from "../../assets/videos/reels/6.mp4";
import airShowVideo from "../../assets/videos/reels/Air Show Highlight V2 MBL Instagram.mp4";
import edgeVideo from "../../assets/videos/reels/EDGE Highlight V1 MBL Instagram.mp4";
import gulfoodVideo from "../../assets/videos/reels/Gulf Food Instagram.mp4";
import pizzaMakingVideo from "../../assets/videos/reels/Pizza Reel V1 MBL.mp4";
import pizza2Video from "../../assets/videos/reels/Pizza Reel V3 MBL.mp4";
import bookVideo from "../../assets/videos/reels/Therapy Reel Book V2 MBL.mp4";
import shakeVideo from "../../assets/videos/reels/Therapy Reel Cold Bevarege V1 MBL.mp4";
import coffeeVideo from "../../assets/videos/reels/Therapy Reel Hot beverage V4 MBL.mp4";
import gameVideo from "../../assets/videos/reels/Therapy Reel Play Station V5 MBL.mp4";
import cafeVideo from "../../assets/videos/reels/Therapy Reel V2 MBL.mp4";
import GetaQuote from "../getaQuote/getaQuote.jsx";
import { Helmet } from "react-helmet-async";
import ScrollToTop from "../scrollToTop.js";

const Home = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [nav3, setNav3] = useState(null);
  const [nav4, setNav4] = useState(null);
  const [nav5, setNav5] = useState(null);
  const [nav6, setNav6] = useState(null);
  const [nav7, setNav7] = useState(null);
  const [nav8, setNav8] = useState(null);
  const [nav9, setNav9] = useState(null);
  const [nav10, setNav10] = useState(null);
  const [nav11, setNav11] = useState(null);
  const [nav12, setNav12] = useState(null);

  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  /*let sliderRef3 = useRef(null);*/
  let sliderRef4 = useRef(null);
  let sliderRef5 = useRef(null);
  let sliderRef6 = useRef(null);
  let sliderRef7 = useRef(null);
  let sliderRef8 = useRef(null);
  let sliderRef9 = useRef(null);
  let sliderRef10 = useRef(null);
  let sliderRef11 = useRef(null);
  let sliderRef12 = useRef(null);
  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
    /*setNav3(sliderRef3);*/
    setNav4(sliderRef4);
    setNav5(sliderRef5);
    setNav6(sliderRef6);
    setNav7(sliderRef7);
    setNav8(sliderRef8);
    setNav9(sliderRef9);
    setNav10(sliderRef10);
    setNav11(sliderRef11);
    setNav12(sliderRef12);
  }, []);
  const next = () => {
    sliderRef1.slickNext();
  };
  const previous = () => {
    sliderRef1.slickPrev();
  };
  const next1 = () => {
    sliderRef2.slickNext();
  };
  const previous1 = () => {
    sliderRef2.slickPrev();
  };
  /*const next2 = () => {
    sliderRef3.slickNext();
  };
  const previous2 = () => {
    sliderRef3.slickPrev();
  };*/
  const next3 = () => {
    sliderRef4.slickNext();
  };
  const previous3 = () => {
    sliderRef4.slickPrev();
  };
  const next4 = () => {
    sliderRef5.slickNext();
  };
  const previous4 = () => {
    sliderRef5.slickPrev();
  };
  const next5 = () => {
    sliderRef6.slickNext();
  };
  const previous5 = () => {
    sliderRef6.slickPrev();
  };
  const next6 = () => {
    sliderRef7.slickNext();
  };
  const previous6 = () => {
    sliderRef7.slickPrev();
  };
  const previous7 = () => {
    sliderRef8.slickPrev();
  };
  const next7 = () => {
    sliderRef8.slickNext();
  };
  const previous8 = () => {
    sliderRef9.slickPrev();
  };
  const next8 = () => {
    sliderRef9.slickNext();
  };
  const previous9 = () => {
    sliderRef10.slickPrev();
  };
  const next9 = () => {
    sliderRef10.slickNext();
  };
  const previous10 = () => {
    sliderRef11.slickPrev();
  };
  const next10 = () => {
    sliderRef11.slickNext();
  };
  const previous11 = () => {
    sliderRef12.slickPrev();
  };
  const next11 = () => {
    sliderRef12.slickNext();
  };

  const [showtab, setShowTab] = useState(1);
  const handleTab = (e) => {
    setShowTab(e);
  };
  const corporate_portfolio = corporateData.map((item) => (
    <Portfolio name={item.name} img={item.image}  />
  ));

  const birthday_portfolio = birthdayData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const headshot_portfolio = headshotData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const fashion_portfolio = fashionData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const wedding_portfolio = weddingData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const realEstate_portfolio = realEstateData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const product_portfolio = productData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const food_portfolio = foodData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const automotive_portfolio = automotiveData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const degree_portfolio = degreeData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const timelapse_portfolio = timelapseData.map((item) => (
    <Portfolio name={item.name} img={item.image} />
  ));

  const [playVideo, setPlayVideo] = useState(false);
  const [playVideo1, setPlayVideo1] = useState(false);
  const [playVideo2, setPlayVideo2] = useState(false);
  const [playVideo3, setPlayVideo3] = useState(false);
  const [playVideo4, setPlayVideo4] = useState(false);

  const togglePlayVideo = () => {
    setPlayVideo(!playVideo);
  };
  const togglePlayVideo1 = () => {
    setPlayVideo1(!playVideo1);
  };
  const togglePlayVideo2 = () => {
    setPlayVideo2(!playVideo2);
  };
  const togglePlayVideo3 = () => {
    setPlayVideo3(!playVideo3);
  };
  const togglePlayVideo4 = () => {
    setPlayVideo4(!playVideo4);
  };

  const [playVideo11, setPlayVideo11] = useState(false);
  const togglePlayVideo11 = () => {
    setPlayVideo11(!playVideo11);
  };
  const [playVideo12, setPlayVideo12] = useState(false);
  const togglePlayVideo12 = () => {
    setPlayVideo12(!playVideo12);
  };
  const [playVideo13, setPlayVideo13] = useState(false);
  const togglePlayVideo13 = () => {
    setPlayVideo13(!playVideo13);
  };
  const [playVideo14, setPlayVideo14] = useState(false);
  const togglePlayVideo14 = () => {
    setPlayVideo14(!playVideo14);
  };
  const [playVideo15, setPlayVideo15] = useState(false);
  const togglePlayVideo15 = () => {
    setPlayVideo15(!playVideo15);
  };
  const [playVideo5, setPlayVideo5] = useState(false);
  const togglePlayVideo5 = () => {
    setPlayVideo5(!playVideo5);
  };
  const [playVideo6, setPlayVideo6] = useState(false);
  const togglePlayVideo6 = () => {
    setPlayVideo6(!playVideo6);
  };
  const [playVideo7, setPlayVideo7] = useState(false);
  const togglePlayVideo7 = () => {
    setPlayVideo7(!playVideo7);
  };
  const [playVideo8, setPlayVideo8] = useState(false);
  const togglePlayVideo8 = () => {
    setPlayVideo8(!playVideo8);
  };
  const [playVideo9, setPlayVideo9] = useState(false);
  const togglePlayVideo9 = () => {
    setPlayVideo9(!playVideo9);
  };
  const [playVideo10, setPlayVideo10] = useState(false);
  const togglePlayVideo10 = () => {
    setPlayVideo10(!playVideo10);
  };
  return (
    <>
    <ScrollToTop />
    <Helmet>
        <meta charSet="utf-8" />
        <title>Best Video Production Company in Dubai</title>
        <link rel="canonical" href="https://zawiyafilms.ae/" />
        <meta
          name="description"
          content="Best Video Production Company in Dubai we crafts visuals, corporate videos to promotional content. We deliver high-quality films that engage and inspire. Call 0524480123"
        />
      </Helmet>
      <Layout>
        <section className="herosection">
          <div className="back_image">
            <img src={heroimage} alt="Al Zawiya Films Production" title="best video production company in dubai" width="100%" height="auto" />
          </div>
          <div className="page_width">
            <div className="herosection_content">
              <div className="banner_text">
                <div className="banner_text_heading slide-right">
                  <h1>
                    <span>
                      <Typewriter
                        words={[
                          "Best Video Production Company In Dubai",
                          "Timelapse Photography In Dubai",
                          "Timelapse Videography In Dubai",
                          "360 Degree Photography In Dubai",
                          "360 Degree Videography In Dubai",
                          "Reels Videography In Dubai",
                          "Corporate Photography In Dubai",
                          "Corporate Videography In Dubai",
                          "Product Photography In Dubai",
                          "Product Videography In Dubai",
                          "Real Estate Photograpy In Dubai",
                          "Real Estate Videography In Dubai",
                          "Headshot Photography In Dubai",
                          "Fashion Photography In Dubai",
                          "Fashion Videography In Dubai",
                          "Food Photography In Dubai",
                          "Food Videography In Dubai",
                          "Wedding Videography In Dubai",
                          "Wedding Photography In Dubai",
                          "Automotive Videography In Dubai",
                          "Automotive Photography In Dubai",
                          "Birthday Photography In Dubai",
                          "Birthday Videography In Dubai",
                          "Commercial Videography In Dubai",
                          "Commercial Photography In Dubai",
                          "Podcast Videogarphy In Dubai",
                          "Event Videography In Dubai",
                          "Event Photography In Dubai",
                          "Professional Shoots In Dubai",
                          "Personal Shoots In Dubai",
                          "Photoshoots In Dubai",
                          "Portrait Photography In Dubai",
                        ]}
                        loop={0}
                        cursor
                        cursorStyle="|"
                        cursorBlinking="true"
                        typeSpeed={70}
                        deleteSpeed={50}
                        delaySpeed={1000}
                      />
                    </span>
                  </h1>
                </div>
                <div className="banner_text_company_name slide-right">
                  <p>Al Zawiya Films Production Dubai</p>
                </div>
                <div className="latest_video_btn slide-right">
                  <button onClick={togglePlayVideo4}>
                    <span>
                      <FaPlay />
                    </span>
                    Latest Video
                  </button>
                  {playVideo4 && (
                    <div className="video_rightside">
                      <div className="overlay" onClick={togglePlayVideo4}></div>
                      <div className="aim_video_content">
                        <ReactPlayer
                          url="https://www.youtube.com/shorts/GlnB8V79fcI"
                          controls="true"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="gisec_video_section">
          <div className="page_width">
            <div className="gisec_video_content">
              <div className="left_text_section">
                <h3 className="section_subtitle">
                  <span>GISEC Global:</span> The World's Leading Cyber Security
                  Exhibition
                </h3>
                <h2 className="section_title">
                  #1 Event Video Production Company In Dubai
                </h2>
                <p>
                  Renowned Dubai-based video production company Al Zawiya Films
                  Production made waves by promoting GISEC Global: The World’s
                  Leading Cyber ​​Security Exhibition. We are well known for
                  video production in Dubai. We cover everything and show what
                  this event is all about. To produce films of the highest
                  caliber, our knowledgeable staff combines innovative methods
                  with cutting edge technology. Our films highlight keynote
                  speakers, present the latest advances in cybersecurity. We
                  capture the event engaging appeal. GISEC Global is expanding
                  its influence and reach, taking its new and distinctive
                  programming to a wider audience. Thanks to its professional
                  and engaging presentation.
                </p>
                <p>
                  As one of Dubai’s top video production companies, Al Zawiya
                  Films Production stands out in making visually appealing &
                  informative content for all sorts of clients. Our work with
                  GISEC Global shows our skill in handling big events and their
                  drive for excellence. The incredible video content not only
                  provides great visibility to the show, but also helps share
                  important cybersecurity information. Al Zawiya ensures that
                  GISEC Global’s big moments reach those who couldn’t make it.
                  We expand the impact of the event and helping more people
                  understand the rapid changes in cybersecurity.
                </p>
              </div>
              <div className="right_video_section fade-in">
                <button className="video_button" onClick={togglePlayVideo}>
                  <img src={gisecimage} alt="best video production company in dubai" title="best video production company in dubai" width="100%" height="auto"
                    loading="lazy" />
                </button>
                {playVideo && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo}></div>
                    <div className="video_content">
                      {/*<video
                        src={gisecvideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />*/}
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=4wAu_-5M67w"
                        controls="true"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="gisec_video_section">
          <div className="page_width">
            <div className="gisec_video_content">
              <div className="right_video_section fade-in">
                <button className="video_button" onClick={togglePlayVideo1}>
                  <img
                    src={aimImage}
                    alt="Corporate Video Production In Dubai"
                    title="best video production company in dubai" width="100%" height="auto"
                    loading="lazy"
                  />
                </button>
                {playVideo1 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo1}></div>
                    <div className="aim_video_content">
                      <video
                        src={aimVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="left_text_section">
                <h3 className="section_subtitle">
                  <span>AIM Congress:</span> The World Leading Investment
                  Platform
                </h3>
                <h2 className="section_title">
                  #1 Corporate Event Video Production Company In Dubai
                </h2>
                <p>
                  Al Zawiya Films Production recently executed an outstanding
                  shoot for the AIM Congress corporate event. The premier
                  gathering of global business leaders, policy makers and
                  innovators. AIM Congress was carefully documented by the
                  experienced Al Zawiya Films Production team in Dubai. Using
                  their expertise in corporate video production the dynamic
                  atmosphere of the event was captured from key events to
                  networking panel discussions. Moreover, ongoing networking
                  sessions tools were also used to save any important moments to
                  incredible detail, revealing the prestige and reflect the
                  international significance of the process.
                </p>
                <p>
                  Al Zawiya Films Production’s Video Shoot stands as a testament
                  to their expertise in corporate video production in Dubai. We
                  are able to deliver a compelling narrative covering key events
                  at the AIM Congress. Thanks to our dynamic storytelling and
                  editing skills. This film is a comprehensive documentary and
                  an effective marketing tool. It also illustrates the
                  importance and magnitude of this problem among global
                  marketers. The production effort of Al Zawiya Films
                  Productions highlighted the importance of the Parliament and
                  expanded its influence. We created a powerful and engaging
                  visual experience that inspired critical thinking and
                  collaboration as the environment changed.
                </p>
              </div>
            </div>
          </div>
        </section>
        <WhyChooseUsSection></WhyChooseUsSection>
        <section className="company_section">
          <div className="page_width">
            <div className="company_section_content">
              <div className="company_sec_title">
                <h2>Al Zawiya Films Production</h2>
              </div>
              <p className="company_para1">
                Are you looking for a high quality video company that is
                professional, creative and reliable? Al Zawiya Films Production
                is here for you. With over 12 years of experience in the UAE, we
                specialize in creating bespoke videos. We always put your
                happiness first, and our long history of video production means
                we know how to keep you happy.
              </p>
              <p className="company_para2">
                At Al Zawiya Films Production, we believe in the power of
                networking. For this reason, we always begin a project with a
                friendly discussion about your needs and vision for your video.
                We will keep in constant communication with you to ensure that
                we fulfill all of your requests. We will talk to you all the way
                and make sure we do exactly what you have asked. With us, it’s
                not just about great video you get a team that listens and cares
                about bringing your vision to life.
              </p>
              <p className="company_para2">
                What sets us apart is our commitment to creating great,
                personalized videos. We're here to develop something exceptional
                that you will love, not just to work. So, if you are ready to
                turn your imagination into a masterpiece, choose Al Zawiya Films
                Production for an experience as enjoyable as it is professional.
              </p>
            </div>
          </div>
        </section>
        <section className="portfolio">
          <div className="page_width">
            <div className="portfolio_content">
              <div className="portfolio_heading">
                <h2>Our Work Portfolio</h2>
              </div>
              <nav className="portfolio_nav">
                <ul className="portfolio_navlinks">
                  <li>
                    <button
                      active
                      className={
                        showtab === 1
                          ? "timelapse_btn active_button"
                          : "timelapse_btn"
                      }
                      onClick={() => handleTab(1)}
                    >
                      Timelapse
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 2
                          ? "360videobooth_btn active_button"
                          : "360videobooth_btn"
                      }
                      onClick={() => handleTab(2)}
                    >
                      360 Video Booth
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 3 ? "reels_btn active_button" : "reels_btn"
                      }
                      onClick={() => handleTab(3)}
                    >
                      Reels
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 4
                          ? "corporate_btn active_button"
                          : "corporate_btn"
                      }
                      onClick={() => handleTab(4)}
                    >
                      Corporate
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 5
                          ? "realestate_btn active_button"
                          : "realestate_btn"
                      }
                      onClick={() => handleTab(5)}
                    >
                      Real Estate
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 6
                          ? "headshot_btn active_button"
                          : "headshot_btn"
                      }
                      onClick={() => handleTab(6)}
                    >
                      Headshot
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 7
                          ? "product_btn active_button"
                          : "product_btn"
                      }
                      onClick={() => handleTab(7)}
                    >
                      Product
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 8
                          ? "fashion_btn active_button"
                          : "fashion_btn"
                      }
                      onClick={() => handleTab(8)}
                    >
                      Fashion
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 9 ? "food_btn active_button" : "food_btn"
                      }
                      onClick={() => handleTab(9)}
                    >
                      Food
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 10
                          ? "wedding_btn active_button"
                          : "wedding_btn"
                      }
                      onClick={() => handleTab(10)}
                    >
                      Wedding
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 11
                          ? "automotive_btn active_button"
                          : "automotive_btn"
                      }
                      onClick={() => handleTab(11)}
                    >
                      Automotive
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        showtab === 12
                          ? "birthday_btn active_button"
                          : "birthday_btn"
                      }
                      onClick={() => handleTab(12)}
                    >
                      Birthday
                    </button>
                  </li>
                  <div className="arrows">
                    <li>
                      <button
                        className="left_arrow"
                        onClick={() => {
                          {
                            previous();
                            previous1();
                            /*previous2();*/
                            previous3();
                            previous4();
                            previous5();
                            previous6();
                            previous7();
                            previous8();
                            previous9();
                            previous10();
                            previous11();
                          }
                        }}
                      >
                        &lt;
                      </button>
                    </li>
                    <li>
                      <button
                        className="right_arrow"
                        onClick={() => {
                          {
                            next();
                            next1();
                            /*next2();*/
                            next3();
                            next4();
                            next5();
                            next6();
                            next7();
                            next8();
                            next9();
                            next10();
                            next11();
                          }
                        }}
                      >
                        &gt;
                      </button>
                    </li>
                  </div>
                </ul>
              </nav>
            </div>

            <div
              className={
                showtab === 1
                  ? "timelapse_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "timelapse_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
                {...settings}
              >
                {timelapse_portfolio}
              </Slider>
            </div>

            <div
              className={
                showtab === 2
                  ? "videobooth_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "videobooth_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav1}
                ref={(slider) => (sliderRef2 = slider)}
                {...settings}
              >
                {degree_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 3
                  ? "reels_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "reels_portfolio_carousel"
              }
            >
              {/*<Slider
                asNavFor={nav3}
                ref={(slider) => (sliderRef3 = slider)}
                {...settings}
              >
                {reels_portfolio}
              </Slider>*/}
              <section className="video_section tColor" asNavFor={nav3}>
                <div className="page_width">
                  <div className="video_section_content">
                    <div className="video1">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo11}
                      >
                        <img
                          src={box}
                          alt="Reels Videography Services in Dubai"
                          title="reels videography services in dubai" width="100%" height="auto" loading="lazy"
                        />
                      </button>
                      {playVideo11 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo11}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={Video1}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video2">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo12}
                      >
                        <img src={airShow} alt="Air Show Highlight" title="reels videography services in dubai" width="100%" height="auto" loading="lazy" />
                      </button>
                      {playVideo12 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo12}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={airShowVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video3">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo13}
                      >
                        <img src={book} alt="Therapy Reel Book V2" title="reels videography services in dubai" width="100%" height="auto" loading="lazy"/>
                      </button>
                      {playVideo13 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo13}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={bookVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video4">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo14}
                      >
                        <img src={cafe} alt="Therapy Board Game Cafe" title="reels videography services in dubai" width="100%" height="auto" loading="lazy" />
                      </button>
                      {playVideo14 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo14}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={cafeVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video5">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo15}
                      >
                        <img src={edge} alt="Edge Reels" title="reels videography services in dubai" width="100%" height="auto" loading="lazy" />
                      </button>
                      {playVideo15 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo15}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={edgeVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video6">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo5}
                      >
                        <img
                          src={game}
                          alt="Therapy Reel Play Station V5 MBL"
                          title="reels videography services in dubai" width="100%" height="auto" loading="lazy"
                        />
                      </button>
                      {playVideo5 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo5}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={gameVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video7">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo6}
                      >
                        <img src={gulfood} alt="GulFood Reels Shoot" title="reels videography services in dubai" width="100%" height="auto" loading="lazy" />
                      </button>
                      {playVideo6 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo6}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={gulfoodVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video8">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo7}
                      >
                        <img src={shake} alt="Therapy Reel Cold Bevarege" title="reels videography services in dubai" width="100%" height="auto" loading="lazy" />
                      </button>
                      {playVideo7 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo7}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={shakeVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video9">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo8}
                      >
                        <img src={pizza2} alt="Pizza Reels Shhot in Dubai" title="reels videography services in dubai" width="100%" height="auto" loading="lazy" />
                      </button>
                      {playVideo8 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo8}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={pizza2Video}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video10">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo9}
                      >
                        <img
                          src={pizzaMaking}
                          alt="Pizza Making Shoot in Dubai"
                          title="reels videography services in dubai" width="100%" height="auto" loading="lazy"
                        />
                      </button>
                      {playVideo9 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo9}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={pizzaMakingVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="video11">
                      <button
                        className="video_button"
                        onClick={togglePlayVideo10}
                      >
                        <img
                          src={coffee}
                          alt="Therapy Reel Hot beverage Shoot in Dubai"
                          title="reels videography services in dubai" width="100%" height="auto" loading="lazy"
                        />
                      </button>
                      {playVideo10 && (
                        <div className="video_rightside">
                          <div
                            className="overlay"
                            onClick={togglePlayVideo10}
                          ></div>
                          <div className="video_content1">
                            <video
                              src={coffeeVideo}
                              width="100%"
                              controls
                              autoplay
                              muted
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              className={
                showtab === 4
                  ? "corporate_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "corporate_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav4}
                ref={(slider) => (sliderRef4 = slider)}
                {...settings}
              >
                {corporate_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 5
                  ? "realestate_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "realestate_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav5}
                ref={(slider) => (sliderRef5 = slider)}
                {...settings}
              >
                {realEstate_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 6
                  ? "headshot_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "headshot_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav6}
                ref={(slider) => (sliderRef6 = slider)}
                {...settings}
              >
                {headshot_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 7
                  ? "product_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "product_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav7}
                ref={(slider) => (sliderRef7 = slider)}
                {...settings}
              >
                {product_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 8
                  ? "fashion_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "fashion_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav8}
                ref={(slider) => (sliderRef8 = slider)}
                {...settings}
              >
                {fashion_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 9
                  ? "food_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "food_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav9}
                ref={(slider) => (sliderRef9 = slider)}
                {...settings}
              >
                {food_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 10
                  ? "wedding_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "wedding_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav10}
                ref={(slider) => (sliderRef10 = slider)}
                {...settings}
              >
                {wedding_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 11
                  ? "automotive_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "automotive_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav11}
                ref={(slider) => (sliderRef11 = slider)}
                {...settings}
              >
                {automotive_portfolio}
              </Slider>
            </div>
            <div
              className={
                showtab === 12
                  ? "birthday_portfolio_carousel active_content fade-in top-bottom-margin"
                  : "birthday_portfolio_carousel"
              }
            >
              <Slider
                asNavFor={nav12}
                ref={(slider) => (sliderRef12 = slider)}
                {...settings}
              >
                {birthday_portfolio}
              </Slider>
            </div>
          </div>
        </section>
        <section className="about_section">
          <div className="page_width">
            <div className="about_section_content">
              <div className="left_section_content">
                <img src={about} alt="Best Video Production Company in Dubai" title="best video production company in dubai" width="100%" height="auto" loading="lazy" />
              </div>
              <div className="right_section_content">
                <h4 classname="about_subtitle">Al Zawiya Films Production</h4>
                <h2 classname="about_title"> About Our Company</h2>

                <div className="about_para">
                  <p className="about_para1">
                    Our filmmaking crew in Dubai is made up of seasoned pros
                    that are highly skilled creators with extensive knowledge of
                    video production. They know how to make videos as simple as
                    possible because they have been in this profession for a
                    while. Creating visually attractive videos that successfully
                    communicate your message is our mission.videos that look
                    great and tell your story well.
                  </p>
                  <p className="about_para2">
                    We provide a variety of corporate services such as video
                    production, event coverage, advertising production,
                    testimonial capture, graphic design, and content creation
                    for social media. In Dubai, we are known for using the right
                    tools and techniques for every project.
                  </p>
                  <p className="about_para3">
                    We at Al Zawiya Films Production Dubai ensures the
                    satisfaction of our clients, and we're aware of what they
                    have to count on from us. Our clients maintain coming again
                    to us due to the fact we continually exceed expectations.
                    Are you in doubt about our suitability for you? Every video
                    production company in Dubai has a different style, so see
                    whether it suits your demands by looking through our
                    previous projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ActiveClientsSection></ActiveClientsSection>
        <section className="gisec_video_section cblack">
          <div className="page_width">
            <div className="gisec_video_content">
              <div className="left_text_section">
                <h3 className="section_subtitle">
                  <span>DRIFT X:</span> Smart Flyer
                </h3>
                <h2 className="section_title">
                  Best Product Advertising Video Production Company In Dubai
                </h2>
                <p>
                  The Drift X Smart Flyer event shoot carried out at Al Zawiya
                  Films Production was a perfect opportunity for us to capture
                  the dynamic energy and excitement that surrounded it. Our
                  production team carefully planned and executed the shoot in
                  such a way as to underscore the stylishness and innovative
                  aspects of the Smart Flyer by seamlessly blending these ideas
                  with the thrill and intensity of drift racing. We used
                  state-of-the-art filming techniques and equipment that enabled
                  every drift, turn or speed burst to be captured in high
                  definition thus bringing viewers right into the middle of
                  action. The outcome is an aesthetically appealing film which
                  not only exhibits ground-breaking technological sophistication
                  of Smart Flyer but also demonstrates how drivers who test
                  these machines strive for perfection.
                </p>
                <p>
                  In making the final movie, our post-production department at
                  Al Zawiya Films Production has concentrated on increasing
                  viewer experience through editing dynamic shots, grading
                  colors, as well as sound design. The idea was to create a
                  seamless mix between fast-paced racing scenes and close-ups
                  revealing some features of Smart Flyer. Furthermore, combining
                  powerful music and sound effects made pictures even more
                  intense so that it seems like audience becomes one with what
                  is going on during this exciting performance. This is
                  exemplified by Drift X Smart Flyer event shoot which shows our
                  commitment towards delivering quality film products.
                </p>
              </div>
              <div className="right_video_section fade-in">
                <button className="video_button" onClick={togglePlayVideo2}>
                  <img src={driftx} alt="DRIFT X: Smart Flyer" title="best video production company in dubai" width="100%" height="auto" loading="lazy"/>
                </button>
                {playVideo2 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo2}></div>
                    <div className="video_content">
                      <video
                        src={driftxVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="gisec_video_section cblack">
          <div className="page_width">
            <div className="gisec_video_content">
              <div className="right_video_section fade-in">
                <button className="video_button" onClick={togglePlayVideo3}>
                  <img src={azizi} alt="Corporate Video Production In Dubai" title="best video production company in dubai" width="100%" height="auto" loading="lazy" />
                </button>
                {playVideo3 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo3}></div>
                    <div className="aim_video_content">
                      <ReactPlayer
                        url="https://www.youtube.com/watch?v=Sd63I314xpQ&t=2s"
                        controls="true"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="left_text_section">
                <h3 className="section_subtitle">
                  <span> Azizi Riviera Dubai:</span> Studio Apartment{" "}
                </h3>
                <h2 className="section_title">
                  Leading Real Estate Video Production Company In Dubai
                </h2>
                <p>
                  We at Al Zawiya Films Production recently captured luxury and
                  modern living at the Azizi Riviera Apartments. Our team had an
                  eye for detail in this project, aimed to highlight the
                  beautiful architectural design and up-market facilities, which
                  define this stunning residential development. We displayed
                  through state of the art camera equipment and techniques
                  panoramic views, classy interior designs and quiet common
                  areas. Every shot was masterfully composed to reflect the
                  elegance and calmness that residents of Azizi Riviera can look
                  forward to thus making it an enticing visual representation of
                  high-end urban lifestyle.
                </p>
                <p>
                  During post-production phase, we from the company Al Zawiya
                  Films Editing worked hard to improve the visual story telling
                  using accurate editing, colour correction and sound design. To
                  match with the apartments elegant aesthetic taste therefore
                  create a warm atmosphere that would attract potential buyers,
                  we chose a soft but engaging background music. It emphasized
                  on comfort blended with luxury that Azizi Riviera offers
                  through dynamic transitions and carefully crafted scenes. The
                  overall film shows how beautiful and functional these houses
                  are while simultaneously creates a sense of aspiration as well
                  as craving which aligns with brand vision thus promise for
                  excellent.
                </p>
              </div>
            </div>
          </div>
        </section>
{/*        <section className="video_quality_section">
          <div className="page_width">
            <div className="video_quality_section_content">
              <div className="video_quality_content">
                <h3 className="section_subtitle font18">
                  <span>DRIFT X:</span> Smart Flyer
                </h3>
                <h2 classname="video_title">
                  Delivering Only The Best Product Advertising in Dubai
                </h2>
                <p className="para">
                  Al Zawiya Films Productions shines as the premier choice for{" "}
                  <span>film production in the UAE</span>, especially within
                  Dubai. Our <span>Video Production Company</span> seamlessly
                  integrates professionalism, meticulous attention to detail,
                  boundless creativity, and a customer-focused approach. The
                  outcome? Numerous triumphs in video and film production,
                  endorsed by delighted clients. Partnering with us guarantees
                  collaboration with a proficient and cutting-edge team capable
                  of delivering excellence in every commissioned project.
                </p>
              </div>
              <div className="video_section_rightside fade-in">
                <video
                  src={video_quality}
                  width="100%"
                  controls
                  autoplay
                  muted
                />
              </div>
            </div>
          </div>
        </section>*/}
        <Faq faqDetails={faqData}></Faq>
        <GetaQuote></GetaQuote>
        <Testimonial></Testimonial>
        <ClientSection></ClientSection>
      </Layout>
    </>
  );
};

export default Home;
