import React from "react";
import { IoMdStar } from "react-icons/io";
import { FaQuoteRight } from "react-icons/fa";
import './testimonial.css'

const TestimonialCard = ({testImage, testTitle, testText }) => {
  return (
    <>
      <div className="testimonial_card">
        <div className="testimonial_card_content">
          <div className="card_image">
            <img src={testImage} title={testTitle} alt="photography services in abu dhabi" width="100%" height="auto" loading="lazy" />
          </div>
          <div className="card_data">
            <div className="stars">
              <IoMdStar />
              <IoMdStar />
              <IoMdStar />
              <IoMdStar />
              <IoMdStar />
            </div>
            <div className="test_title">
                <h4>{testTitle}</h4>
            </div>
          </div>
        </div>
        <p className="test_text"> {testText}</p>
        <div className="test_icon"> <FaQuoteRight /> </div>
      </div>
    </>
  );
};
export default TestimonialCard;
