import React, { useState, useEffect } from "react";
import axios from "axios";
import ReplyForm from "./replyForm";
import "../../pages/PostPages/blogsPost.css";

const DisplayComments = (postid) => {
  const [commentsList, setCommentsList] = useState([]);
  /*const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);

  const handleLike = async (id) => {
    try {
      await axios
        .put(`http://zawiyafilms.ae/api/update_comment/${id}/like`)
        .then(function (response) {
          console.log(response);
          setLike(true);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDislike = async (id) => {
    try {
      await axios
        .put(`http://zawiyafilms.ae/api/update_comment/${id}/dislike`)
        .then(function (response) {
          console.log(response);
          setDislike(true);
        });
    } catch (error) {
      console.log(error);
    }
  };*/

  const [selected, setSelected] = useState(null);

  const handleReply = async (index) => {
    if (selected === index) {
      setSelected(null);
    }
    setSelected(index);
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        await axios
          .get(`http://zawiyafilms.ae/api/get_comment?${postid}`)
          .then(function (response) {
            setCommentsList(response.data);
          });
      } catch (error) {
        console.log(error);
      }
    };
    fetchComments();
  }, [postid]);

  return (
    <>
      <section className="listComments">
        <div className="page_width">
          <div className="listComments_content">
            <h2>Comments ({commentsList.length})</h2>
            {commentsList.map((item, index) => (
              <div key={item._id} className="commentsData">
                <div className="name_date">
                  <div className="name">
                    <h3>{item.name}</h3>
                  </div>
                  <div className="date">{item.date}</div>
                </div>
                <p>{item.comment}</p>
                <div className="btns">
                  {/*<button
                    className={`btn ${
                      like ? "likedBlue" : "like"
                    } `}
                    onClick={() => {
                      handleLike(item._id);
                    }}
                  >
                    <BiSolidLike />
                    <div className="count">{item.likes}</div>
                  </button>
                  <button
                    className={`btn ${
                      dislike ? "dislikedRed" : "dislike"
                    }`}
                    onClick={() => {
                      handleDislike(item._id);
                    }}
                  >
                    <BiSolidDislike />

                    <div className="count">{item.dislikes}</div>
                  </button>*/}
                  <button
                    className="btn replyBtn"
                    onClick={() => handleReply(index)}
                  >
                    Reply
                  </button>
                </div>
                {selected === index && (
                  <ReplyForm
                    id={item._id}
                    className={
                      selected === index
                        ? "replyFormShow formShow"
                        : "replyFormShow"
                    }
                  ></ReplyForm>
                )}
                {/*<div className="replies">
                  {item.replies.map((item, index) => (
                    <div key={item._id} className="commentsData">
                      <div className="name_date">
                        <div className="name">
                          <h3>{item.name}</h3>
                        </div>
                        <div className="date">{item.date}</div>
                      </div>
                      <p>{item.comment}</p>
                       <div className="btns">
                        <button
                          className={`btn ${
                            selectBtnLike === "true" ? "likedBlue" : "like"
                          } `}
                          onClick={() => {
                            handleLike(item._id);
                          }}
                        >
                          <BiSolidLike />
                          <div className="count">{item.likes}</div>
                        </button>
                        <button
                          className={`btn ${
                            selectBtnDisLike === "true"
                              ? "dislikedRed"
                              : "dislike"
                          }`}
                          onClick={() => {
                            handleDislike(item._id);
                          }}
                        >
                          <BiSolidDislike />

                          <div className="count">{item.dislikes}</div>
                        </button>
                        <button
                          className="btn replyBtn"
                          onClick={() => handleReply(index)}
                        >
                          Reply
                        </button>
                      </div>
                      {selected === index && (
                        <ReplyForm
                          id={item._id}
                          className={
                            selected === index
                              ? "replyFormShow formShow"
                              : "replyFormShow"
                          }
                        ></ReplyForm>
                      )}
                    </div>
                  ))}
                </div>*/}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default DisplayComments;
