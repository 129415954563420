import React, { useState } from "react";
import "./image.css";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Captions,
  Fullscreen,
  Slideshow,
  Thumbnails,
  Video,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Images = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [lightBoxDisplay, setLightBoxDisplay] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setLightBoxDisplay(true);
    setOpen(true);
  };
  return (
    <>
      <div className="images_container">
        <div className="page_width">
          <div className="images_container_content">
            {data.map((item, index) => (
              <div key={index} className="image">
                <img
                  title={item.title}
                  src={item.src}
                  alt={item.title}
                  width="100%"
                  height="auto"
                  loading="lazy"
                  onClick={() => handleClick(item, index)}
                />
              </div>
            ))}
            {lightBoxDisplay ? (
              <Lightbox
                plugins={[Captions, Zoom, Fullscreen, Slideshow, Thumbnails, Video]}
                open={open}
                close={() => setOpen(false)}
                slides={data}
                index={currentIndex}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Images;
