//Import Birthday Images Start
import bImg1 from "../../assets/images/birthday/1.webp";
import bImg2 from "../../assets/images/birthday/2.webp";
import bImg3 from "../../assets/images/birthday/3.webp";
import bImg4 from "../../assets/images/birthday/4.webp";
import bImg5 from "../../assets/images/birthday/5.webp";
import bImg6 from "../../assets/images/birthday/6.webp";
import bImg7 from "../../assets/images/birthday/7.webp";
import bImg8 from "../../assets/images/birthday/8.webp";
import bImg9 from "../../assets/images/birthday/9.webp";
import bImg10 from "../../assets/images/birthday/10.webp";
import bImg11 from "../../assets/images/birthday/11.webp";
import bImg12 from "../../assets/images/birthday/12.webp";
import bImg13 from "../../assets/images/birthday/13.webp";
//Import Birthday Images End

//Import Corporate Images Start
import cImg1 from "../../assets/images/corporate/1.webp";
import cImg4 from "../../assets/images/corporate/4.webp";
import cImg5 from "../../assets/images/corporate/5.webp";
import cImg6 from "../../assets/images/corporate/6.webp";
import cImg7 from "../../assets/images/corporate/7.webp";
import cImg13 from "../../assets/images/corporate/13.webp";
import cImg18 from "../../assets/images/corporate/18.webp";
import cImg19 from "../../assets/images/corporate/19.webp";
import cImg20 from "../../assets/images/corporate/20.webp";
//Import Corporate Images End

//Import Headshot Images Start
import hsImg1 from "../../assets/images/headshot/1.webp";
import hsImg2 from "../../assets/images/headshot/2.webp";
import hsImg3 from "../../assets/images/headshot/3.webp";
import hsImg4 from "../../assets/images/headshot/4.webp";
import hsImg5 from "../../assets/images/headshot/5.webp";
import hsImg6 from "../../assets/images/headshot/6.webp";
import hsImg7 from "../../assets/images/headshot/7.webp";
import hsImg8 from "../../assets/images/headshot/8.webp";
import hsImg9 from "../../assets/images/headshot/9.webp";
import hsImg10 from "../../assets/images/headshot/10.webp";
import hsImg11 from "../../assets/images/headshot/11.webp";
import hsImg12 from "../../assets/images/headshot/12.webp";
import hsImg13 from "../../assets/images/headshot/13.webp";
import hsImg14 from "../../assets/images/headshot/14.webp";
import hsImg15 from "../../assets/images/headshot/15.webp";
import hsImg16 from "../../assets/images/headshot/16.webp";
import hsImg17 from "../../assets/images/headshot/17.webp";
import hsImg18 from "../../assets/images/headshot/18.webp";
import hsImg20 from "../../assets/images/headshot/20.webp";
import hsImg21 from "../../assets/images/headshot/21.webp";
import hsImg22 from "../../assets/images/headshot/22.webp";
//Import Headshot Images End

//Import Fashion Images Start
import fImg1 from "../../assets/images/fashion/1.webp";
import fImg2 from "../../assets/images/fashion/2.webp";
import fImg3 from "../../assets/images/fashion/3.webp";
import fImg4 from "../../assets/images/fashion/4.webp";
import fImg5 from "../../assets/images/fashion/5.webp";
import fImg6 from "../../assets/images/fashion/6.webp";
import fImg7 from "../../assets/images/fashion/7.webp";
import fImg8 from "../../assets/images/fashion/8.webp";
import fImg9 from "../../assets/images/fashion/9.webp";
import fImg10 from "../../assets/images/fashion/10.webp";
import fImg11 from "../../assets/images/fashion/11.webp";
import fImg12 from "../../assets/images/fashion/12.webp";
import fImg14 from "../../assets/images/fashion/14.webp";
import fImg15 from "../../assets/images/fashion/15.webp";
import fImg16 from "../../assets/images/fashion/16.webp";
import fImg18 from "../../assets/images/fashion/18.webp";
import fImg19 from "../../assets/images/fashion/19.webp";
import fImg20 from "../../assets/images/fashion/20.webp";
import fImg21 from "../../assets/images/fashion/21.webp";
import fImg22 from "../../assets/images/fashion/22.webp";
import fImg25 from "../../assets/images/fashion/25.webp";
import fImg27 from "../../assets/images/fashion/27.webp";
import fImg29 from "../../assets/images/fashion/29.webp";
import fImg30 from "../../assets/images/fashion/30.webp";
import fImg31 from "../../assets/images/fashion/31.webp";
import fImg32 from "../../assets/images/fashion/32.webp";
import fImg33 from "../../assets/images/fashion/33.webp";
import fImg34 from "../../assets/images/fashion/34.webp";
import fImg35 from "../../assets/images/fashion/35.webp";
import fImg36 from "../../assets/images/fashion/36.webp";
import fImg37 from "../../assets/images/fashion/37.webp";
//Import Fashion Images End

//Import Wedding Images Start 
import wImg1 from "../../assets/images/wedding/1.webp";
import wImg2 from "../../assets/images/wedding/2.webp";
import wImg3 from "../../assets/images/wedding/3.webp";
import wImg4 from "../../assets/images/wedding/4.webp";
import wImg5 from "../../assets/images/wedding/5.webp";
import wImg6 from "../../assets/images/wedding/6.webp";
import wImg7 from "../../assets/images/wedding/7.webp";
import wImg10 from "../../assets/images/wedding/10.webp";
import wImg11 from "../../assets/images/wedding/11.webp";
import wImg14 from "../../assets/images/wedding/14.webp";
import wImg17 from "../../assets/images/wedding/17.webp";
import wImg18 from "../../assets/images/wedding/18.webp";
import wImg19 from "../../assets/images/wedding/19.webp";
import wImg20 from "../../assets/images/wedding/20.webp";
import wImg21 from "../../assets/images/wedding/21.webp";
import wImg22 from "../../assets/images/wedding/22.webp";
import wImg23 from "../../assets/images/wedding/23.webp";
import wImg24 from "../../assets/images/wedding/24.webp";
import wImg25 from "../../assets/images/wedding/25.webp";
import wImg26 from "../../assets/images/wedding/26.webp";
import wImg27 from "../../assets/images/wedding/27.webp";
// Import Wedding Images End

//Import Real Estate Images Start 
import rImg1 from "../../assets/images/realestate/1.webp";
import rImg2 from "../../assets/images/realestate/2.webp";
import rImg4 from "../../assets/images/realestate/4.webp";
import rImg5 from "../../assets/images/realestate/5.webp";
import rImg6 from "../../assets/images/realestate/6.webp";
import rImg7 from "../../assets/images/realestate/7.webp";
import rImg9 from "../../assets/images/realestate/9.webp";
import rImg10 from "../../assets/images/realestate/10.webp";
import rImg11 from "../../assets/images/realestate/11.webp";
//Import Real Estate Images End

// Import Product Images Start 
import pImg14 from "../../assets/images/product/14.webp";
import pImg17 from "../../assets/images/product/17.webp";
import pImg18 from "../../assets/images/product/18.webp";
import pImg19 from "../../assets/images/product/19.webp";
import pImg20 from "../../assets/images/product/20.webp";
import pImg22 from "../../assets/images/product/22.webp";
import pImg23 from "../../assets/images/product/23.webp";
import pImg24 from "../../assets/images/product/24.webp";
import pImg26 from "../../assets/images/product/26.webp";
import pImg27 from "../../assets/images/product/27.webp";
import pImg28 from "../../assets/images/product/28.webp";
import pImg33 from "../../assets/images/product/33.webp";
import pImg35 from "../../assets/images/product/35.webp";
import pImg39 from "../../assets/images/product/39.webp";
import pImg40 from "../../assets/images/product/40.webp";
// Import Product Images End

// Import Food Images Start
import foodImg1 from "../../assets/images/food/1.webp";
import foodImg2 from "../../assets/images/food/2.webp";
import foodImg3 from "../../assets/images/food/3.webp";
import foodImg4 from "../../assets/images/food/4.webp";
import foodImg5 from "../../assets/images/food/5.webp";
import foodImg6 from "../../assets/images/food/6.webp";
import foodImg7 from "../../assets/images/food/7.webp";
import foodImg8 from "../../assets/images/food/8.webp";
import foodImg10 from "../../assets/images/food/10.webp";
// Import Food Images End

//Import Automotiove Images Start 
import aImg16 from "../../assets/images/automotive/16.webp";
import aImg5 from "../../assets/images/automotive/5.webp";
import aImg6 from "../../assets/images/automotive/6.webp";
import aImg7 from "../../assets/images/automotive/7.webp";
import aImg9 from "../../assets/images/automotive/9.webp";
import aImg10 from "../../assets/images/automotive/10.webp";
import aImg13 from "../../assets/images/automotive/13.webp";
import aImg22 from "../../assets/images/automotive/22.webp";
//Import Automotive Images End

//Import 360 Degree Images Start
import dImg1 from "../../assets/images/360Degree/1.webp";
import dImg2 from "../../assets/images/360Degree/2.webp";
import dImg3 from "../../assets/images/360Degree/3.webp";
import dImg4 from "../../assets/images/360Degree/4.webp";
import dImg5 from "../../assets/images/360Degree/5.webp";
//Import 360 Degree Images End

//Import Timelapse Images Start
import tImg3 from "../../assets/images/timelapse/3.webp";
import tImg4 from "../../assets/images/timelapse/4.webp";
import tImg5 from "../../assets/images/timelapse/5.webp";
import tImg8 from "../../assets/images/timelapse/8.webp";
import tImg9 from "../../assets/images/timelapse/9.webp";
import tImg11 from "../../assets/images/timelapse/11.webp";
import tImg12 from "../../assets/images/timelapse/12.webp";
import tImg15 from "../../assets/images/timelapse/15.webp";
import tImg17 from "../../assets/images/timelapse/17.webp";
import tImg18 from "../../assets/images/timelapse/18.webp";
import tImg19 from "../../assets/images/timelapse/19.webp";
import tImg20 from "../../assets/images/timelapse/20.webp";
import tImg21 from "../../assets/images/timelapse/21.webp";
import tImg22 from "../../assets/images/timelapse/22.webp";
import tImg23 from "../../assets/images/timelapse/23.webp";
import tImg25 from "../../assets/images/timelapse/25.webp";
import tImg27 from "../../assets/images/timelapse/27.webp";
import tImg29 from "../../assets/images/timelapse/29.webp";
import tImg30 from "../../assets/images/timelapse/30.webp";
import tImg31 from "../../assets/images/timelapse/31.webp";
import tImg32 from "../../assets/images/timelapse/32.webp";
//Import Timelapse Images End 

export const settings = {
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 2000,
  cssEase: "linear",
  keyBoardControl: true,
  partialVisible: true,
  focusOnSelect: true,
  centermode: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const testSettings = {
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 2000,
  cssEase: "linear",
  keyBoardControl: true,
  partialVisible: true,
  focusOnSelect: true,
  centermode: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const corporateData = [
  {
    id: 1,
    image: cImg1,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 4,
    image: cImg4,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 5,
    image: cImg5,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 6,
    image: cImg6,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 7,
    image: cImg7,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 13,
    image: cImg13,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 18,
    image: cImg18,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 19,
    image: cImg19,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 20,
    image: cImg20,
    name: "Corporate Videography in Dubai",
  },
];
export const birthdayData = [
  {
    id: 1,
    image: bImg1,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 2,
    image: bImg2,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 3,
    image: bImg3,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 4,
    image: bImg4,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 5,
    image: bImg5,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 6,
    image: bImg6,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 7,
    image: bImg7,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 8,
    image: bImg8,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 9,
    image: bImg9,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 10,
    image: bImg10,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 11,
    image: bImg11,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 12,
    image: bImg12,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 13,
    image: bImg13,
    name: "Birthday Photography in Dubai",
  },
  
];
export const headshotData = [
  {
    id: 1,
    image: hsImg1,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 2,
    image: hsImg2,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 3,
    image: hsImg3,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 4,
    image: hsImg4,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 5,
    image: hsImg5,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 6,
    image: hsImg6,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 7,
    image: hsImg7,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 8,
    image: hsImg8,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 9,
    image: hsImg9,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 10,
    image: hsImg10,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 11,
    image: hsImg11,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 12,
    image: hsImg12,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 13,
    image: hsImg13,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 14,
    image: hsImg14,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 15,
    image: hsImg15,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 16,
    image: hsImg16,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 17,
    image: hsImg17,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 18,
    image: hsImg18,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 20,
    image: hsImg20,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 21,
    image: hsImg21,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 22,
    image: hsImg22,
    name: "Headshot Photography in Dubai",
  },
];
export const fashionData = [
  {
    id: 1,
    image: fImg1,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 2,
    image: fImg2,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 3,
    image: fImg3,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 4,
    image: fImg4,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 5,
    image: fImg5,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 6,
    image: fImg6,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 7,
    image: fImg7,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 8,
    image: fImg8,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 9,
    image: fImg9,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 10,
    image: fImg10,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 11,
    image: fImg11,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 12,
    image: fImg12,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 14,
    image: fImg14,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 15,
    image: fImg15,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 16,
    image: fImg16,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 18,
    image: fImg18,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 19,
    image: fImg19,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 20,
    image: fImg20,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 21,
    image: fImg21,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 22,
    image: fImg22,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 25,
    image: fImg25,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 27,
    image: fImg27,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 29,
    image: fImg29,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 30,
    image: fImg30,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 31,
    image: fImg31,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 32,
    image: fImg32,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 33,
    image: fImg33,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 34,
    image: fImg34,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 35,
    image: fImg35,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 36,
    image: fImg36,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 37,
    image: fImg37,
    name: "Fashion Videography in Dubai",
  },
];
export const weddingData = [
  {
    id: 1,
    image: wImg1,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 2,
    image: wImg2,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 3,
    image: wImg3,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 4,
    image: wImg4,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 5,
    image: wImg5,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 6,
    image: wImg6,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 7,
    image: wImg7,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 10,
    image: wImg10,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 11,
    image: wImg11,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 20,
    image: wImg20,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 21,
    image: wImg21,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 22,
    image: wImg22,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 23,
    image: wImg23,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 24,
    image: wImg24,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 25,
    image: wImg25,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 26,
    image: wImg26,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 27,
    image: wImg27,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 14,
    image: wImg14,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 17,
    image: wImg17,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 18,
    image: wImg18,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 19,
    image: wImg19,
    name: "Wedding Videography in Dubai",
  },
];
export const realEstateData = [
  {
    id: 1,
    image: rImg1,
    name: "real estate Photography in Dubai",
  },
  {
    id: 2,
    image: rImg2,
    name: "real estate Photography in Dubai",
  },
  {
    id: 4,
    image: rImg4,
    name: "real estate Videography in Dubai",
  },
  {
    id: 5,
    image: rImg5,
    name: "real estate Photography in Dubai",
  },
  {
    id: 6,
    image: rImg6,
    name: "real estate Photography in Dubai",
  },
  {
    id: 7,
    image: rImg7,
    name: "real estate Videography in Dubai",
  },
  {
    id: 9,
    image: rImg9,
    name: "real estate Photography in Dubai",
  },
  {
    id: 10,
    image: rImg10,
    name: "real estate Photography in Dubai",
  },
  {
    id: 11,
    image: rImg11,
    name: "real estate Videography in Dubai",
  },
];
export const productData = [
  {
    id: 14,
    image: pImg14,
    name: "product Photography in Dubai",
  },
  {
    id: 17,
    image: pImg17,
    name: "product Photography in Dubai",
  },
  {
    id: 18,
    image: pImg18,
    name: "product Photography in Dubai",
  },
  {
    id: 19,
    image: pImg19,
    name: "product Videography in Dubai",
  },
  {
    id: 20,
    image: pImg20,
    name: "product Videography in Dubai",
  },
  {
    id: 22,
    image: pImg22,
    name: "product Photography in Dubai",
  },
  {
    id: 23,
    image: pImg23,
    name: "product Videography in Dubai",
  },
  {
    id: 24,
    image: pImg24,
    name: "product Videography in Dubai",
  },
  {
    id: 26,
    image: pImg26,
    name: "product Photography in Dubai",
  },
  {
    id: 27,
    image: pImg27,
    name: "product Videography in Dubai",
  },
  {
    id: 28,
    image: pImg28,
    name: "product Videography in Dubai",
  },
  {
    id: 33,
    image: pImg33,
    name: "product Photography in Dubai",
  },
  {
    id: 35,
    image: pImg35,
    name: "product Videography in Dubai",
  },
  {
    id: 39,
    image: pImg39,
    name: "product Videography in Dubai",
  },
  {
    id: 40,
    image: pImg40,
    name: "product Videography in Dubai",
  },
];
export const foodData = [
  {
    id: 1,
    image: foodImg1,
    name: "food Photography in Dubai",
  },
  {
    id: 2,
    image: foodImg2,
    name: "food Photography in Dubai",
  },
  {
    id: 3,
    image: foodImg3,
    name: "food Videography in Dubai",
  },
  {
    id: 4,
    image: foodImg4,
    name: "food Videography in Dubai",
  },
  {
    id: 5,
    image: foodImg5,
    name: "food Photography in Dubai",
  },
  {
    id: 6,
    image: foodImg6,
    name: "food Photography in Dubai",
  },
  {
    id: 7,
    image: foodImg7,
    name: "food Videography in Dubai",
  },
  {
    id: 8,
    image: foodImg8,
    name: "food Videography in Dubai",
  },
  {
    id: 10,
    image: foodImg10,
    name: "food Photography in Dubai",
  },
];
export const automotiveData = [
  {
    id: 5,
    image: aImg5,
    name: "automotive Photography in Dubai",
  },
  {
    id: 6,
    image: aImg6,
    name: "automotive Photography in Dubai",
  },
  {
    id: 7,
    image: aImg7,
    name: "automotive Videography in Dubai",
  },
  {
    id: 9,
    image: aImg9,
    name: "automotive Photography in Dubai",
  },
  {
    id: 10,
    image: aImg10,
    name: "automotive Photography in Dubai",
  },
  {
    id: 13,
    image: aImg13,
    name: "automotive Videography in Dubai",
  },
  {
    id: 22,
    image: aImg22,
    name: "automotive Videography in Dubai",
  },
  {
    id: 16,
    image: aImg16,
    name: "automotive Videography in Dubai",
  },
];
export const degreeData = [
  {
    id: 1,
    image: dImg1,
    name: "360 Degree Photography in Dubai",
  },
  {
    id: 2,
    image: dImg2,
    name: "360 Degree Photography in Dubai",
  },
  {
    id: 3,
    image: dImg3,
    name: "360 Degree Videography in Dubai",
  },
  {
    id: 4,
    image: dImg4,
    name: "360 Degree Videography in Dubai",
  },
  {
    id: 5,
    image: dImg5,
    name: "360 Degree Photography in Dubai",
  },
];
export const timelapseData = [
  {
    id: 12,
    image: tImg12,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 17,
    image: tImg17,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 5,
    image: tImg5,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 22,
    image: tImg22,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 3,
    image: tImg3,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 4,
    image: tImg4,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 8,
    image: tImg8,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 9,
    image: tImg9,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 11,
    image: tImg11,
    name: "Timelapse Videography in Dubai",
  },
  
  {
    id: 15,
    image: tImg15,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 18,
    image: tImg18,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 19,
    image: tImg19,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 20,
    image: tImg20,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 21,
    image: tImg21,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 23,
    image: tImg23,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 25,
    image: tImg25,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 27,
    image: tImg27,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 29,
    image: tImg29,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 30,
    image: tImg30,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 31,
    image: tImg31,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 32,
    image: tImg32,
    name: "Timelapse Photography in Dubai",
  },
];