import azizi from "../../assets/images/our_clients/azizi.webp";
import connecting_minds from "../../assets/images/our_clients/connecting_minds.webp";
import dmk from "../../assets/images/our_clients/dmk.webp";
import emaar from "../../assets/images/our_clients/emaar.webp";
import jumeirah_luxury from "../../assets/images/our_clients/jumeirah_luxury.webp";
import gisec from "../../assets/images/our_clients/gisec_global.webp";
import driftx from "../../assets/images/our_clients/driftx.webp";
import cocacola from "../../assets/images/our_clients/cocacola.webp";
import aim from "../../assets/images/our_clients/aim-congress.webp";
import edge from "../../assets/images/our_clients/edge.webp";
import elista from "../../assets/images/our_clients/elista.webp";
import emirates from "../../assets/images/our_clients/emirates.webp";
import germanConsulate from "../../assets/images/our_clients/german_consulate.webp";
import gitex from "../../assets/images/our_clients/gitex.webp";
import gulfood from "../../assets/images/our_clients/gulfood.webp";
import lalajaan from "../../assets/images/our_clients/lalajaan.webp";
import samanaDevelopers from "../../assets/images/our_clients/samana_developers.webp";
import arabHealth from "../../assets/images/our_clients/arab_health.webp";
import eywa from "../../assets/images/our_clients/eywa.webp";
import toshiba from "../../assets/images/our_clients/toshiba.webp";

export const clientsData = [
  {
    title: "azizi",
    image: azizi,
    alt: "video production company in uae"
  },
  {
    title: "connecting_minds",
    image: connecting_minds,
    alt: "video production company in uae"
  },
  {
    title: "dmk",
    image: dmk,
    alt: "video production company in uae"
  },
  {
    title: "emaar",
    image: emaar,
    alt: "video production company in uae"
  },
  {
    title: "jumeirah_luxury",
    image: jumeirah_luxury,
    alt: "video production company in uae"
  },
  {
    title: "gitex",
    image: gitex,
    alt: "video production company in uae"
  },
  {
    title: "Aim Congress",
    image: aim,
    alt: "video production company in uae"
  },
  {
    title: "Drift X",
    image: driftx,
    alt: "video production company in uae"
  },
  {
    title: "CocaCola",
    image: cocacola,
    alt: "video production company in uae"
  },
  {
    title: "ARAB HEALTH",
    image: arabHealth,
    alt: "video production company in uae"
  },
  {
    title: "Samana Developers",
    image: samanaDevelopers,
    alt: "video production company in uae"
  },
  {
    title: "Emirates",
    image: emirates,
    alt: "video production company in uae"
  },
  {
    title: "Edge",
    image: edge,
    alt: "video production company in uae"
  },
  {
    title: "Elista",
    image: elista,
    alt: "video production company in uae"
  },
  {
    title: "German Consulate",
    image: germanConsulate,
    alt: "video production company in uae"
  },
  {
    title: "Gitex",
    image: gitex,
    alt: "video production company in uae" 
  },
  {
    title: "Gul Food",
    image: gulfood,
    alt: "video production company in uae",
  }, 
  {
    title: "Lala Jaan",
    image: lalajaan,
    alt: "video production company in uae"
  },
  {
    title: "Eywa",
    image: eywa,
    alt: "video production company in uae"
  },
  {
    title: "Toshiba",
    image: toshiba,
    alt: "video production company in uae"
  }
];
export const settings1 = {
  arrows: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 2000,
  cssEase: "linear",
  keyBoardControl: true,
  partialVisible: true,
  focusOnSelect: true,
  centermode: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
